<template>
    <div class="container text-center mb-5 mt-5 py-5" id="conten">
        <div v-if="switchView">
            <div class="mb-5 mt-5">
                <button type="button" class="btn btn-primary" @click="modalBodega(0, 0)">Crear nueva ciudad</button>
            </div>
            <div v-for="(i,k) in listarCiuddad" :key="k" class="btnCity d-flex align-items-center px-4 py-3 mb-4"
                @click="lasBodegas(i.id, i.name)">
                <i class='bx bx-map-pin'></i>
                <p class="ml-2 mb-0 text-capitalize font-weight-bold">{{ i.id }}. {{ i.name }}
                    <span></span></p>
            </div>
        </div>
        <div v-else>
            <div class="mb-5 mt-5">
                <button type="button" class="btn btn-primary" @click="modalBodega(0, 1)">Crear nueva bodega</button>
            </div>

            <div class="mb-5 text-left px-5">
                <i class='bx bx-chevron-left btnAtras' @click="switchView = true"></i>
            </div>

            <!-- tarjetas para bodegas -->
            <div class="d-md-flex align-items-center justify-content-center">
                <div v-for="(i,k) in listarBodegas" :key="k" class="targetas mb-4 mx-2 p-1">
                    <div class="card-body">
                        <h5 class="card-title">{{ i.name }}</h5>
                        <p class="card-text">{{ i.address }}</p>
                        <div class="d-flex align-items-center justify-content-between">
                            <p class="mb-0">{{ city }}</p>
                            <a :href="`https://www.google.com/maps/@${i.latitude},${i.longitude},14z`" target="_black">
                                <i class='bx bx-map' style="font-size: 20px;"></i>
                            </a>
                        </div>
                        <p class="card-text">{{ i.phone }}</p>
                    </div>
                    <div class="card-footer btn-group" role="group">
                        <button type="button" class="btn btn-primary" @click="modalBodega(i, 2)">Editar</button>
                        <button type="button" class="btn btn-danger" @click="confirmarbtn(i.id,'eliminar')">Eliminar</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Cargando... -->
        <div v-if="loading">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>

        <!-- Modales -->
        <div v-if="modaleSwitch" class="modal" tabindex="-1">
            <div class="modal-dialog overflow-auto">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ Object.values(this.modalData.tipos)[this.modalData.tipo] }}</h5>
                        <button type="button" class="btn-close border-0" data-bs-dismiss="modal" aria-label="Close" @click="cerrarModal()"><i class='bx bx-x'></i></button>
                    </div>

                    <!-- Crear Ciudad -->
                    <div v-if="modalData.tipo == 0" class="modal-body">
                        <div class="form-group text-left">
                            <label for="name">Nombre de la Ciudad</label>
                            <input type="text" class="form-control" id="name" v-model="modalData.fullDataModal.name">
                        </div>
                        <div class="form-group text-left">
                            <label for="prefijo">Prefijo</label>
                            <input type="text" class="form-control" id="prefijo" v-model="modalData.fullDataModal.slug_name">
                        </div>
                    </div>

                    <!-- Crear Bodega -->
                    <div v-else-if="modalData.tipo == 1" class="modal-body">
                        <div class="form-group text-left">
                            <label for="name">Nombre de la Bodega</label>
                            <input type="text" class="form-control" id="name" required v-model="modalData.fullDataModal.name">
                        </div>
                        <div class="form-group text-left">
                            <label for="direccion">Dirección</label>
                            <input type="text" class="form-control" id="prefijo" required v-model="modalData.fullDataModal.address">
                        </div>
                        <div class="form-group text-left">
                            <label for="latitude">Latitude</label>
                            <input type="text" class="form-control" id="latitude" required v-model="modalData.fullDataModal.latitude">
                        </div>
                        <div class="form-group text-left">
                            <label for="longitude">Longitude</label>
                            <input type="text" class="form-control" id="longitude" required v-model="modalData.fullDataModal.longitude">
                        </div>
                    </div>

                    <!-- Editar Bodega -->
                    <div v-else-if="modalData.tipo == 2" class="modal-body">
                        <div class="form-group text-left">
                            <label for="name">Nombre de la Bodega</label>
                            <input type="text" class="form-control" id="name" required v-model="modalData.fullDataModal.name">
                        </div>
                        <div class="form-group text-left">
                            <label for="direccion">Dirección</label>
                            <input type="text" class="form-control" id="prefijo" required v-model="modalData.fullDataModal.address">
                        </div>
                        <div class="form-group text-left">
                            <label for="latitude">Latitude</label>
                            <input type="text" class="form-control" id="latitude" required v-model="modalData.fullDataModal.latitude">
                        </div>
                        <div class="form-group text-left">
                            <label for="longitude">Longitude</label>
                            <input type="text" class="form-control" id="longitude" required v-model="modalData.fullDataModal.longitude">
                        </div>
                    </div>


                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="cerrarModal()">Cerrar</button>
                        <button v-if="modalData.tipo == 0" type="button" class="btn btn-primary" @click="confirmarbtn(0, 'crear ciudad')">Crear</button>
                        <button v-if="modalData.tipo == 1" type="button" class="btn btn-primary" @click="confirmarbtn(0, 'crear la bodega')">Crear</button>
                        <button v-if="modalData.tipo == 2" type="button" class="btn btn-primary" @click="confirmarbtn(0, 'guardar')">Guardar</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Botons confirmar -->
        <div v-if="btnconfirmar != ''" class="btnconfirmar">
            <div>
                <p>¿Seguro que desea {{ btnconfirmar.title }}?</p>
                <button type="button" class="btn btn-secondary" @click="cerrarConfirmar">No</button>
                <!-- <button v-if="btnconfirmar == 'guardar'" type="button" class="btn btn-primary ml-2" @click="crearProducto">Si</button> -->
                <button v-if="modalData.tipo == 0 && btnconfirmar.title != 'eliminar'" type="button" class="btn btn-primary ml-2" @click="crearCiudad()">Si</button>
                <button v-if="modalData.tipo == 1" type="button" class="btn btn-primary ml-2" @click="crearBodega()">Si</button>
                <button v-if="modalData.tipo == 2" type="button" class="btn btn-primary ml-2" @click="guardar()">Si</button>
                <button v-if="btnconfirmar.title == 'eliminar'" type="button" class="btn btn-danger ml-2" data-dismiss="modal" @click="eliminarBodega(btnconfirmar.id)">Si</button>
            </div>
        </div>

        <!-- Notificacion toast -->
        <div v-if="msgtoast != ''" class="align-items-center text-white border-0 position-fixed" :class="{'bg-success': msgtoast != 'error', 'bg-danger': msgtoast == 'error'}" style="right:35px; bottom:35px; z-index: 1060;" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="d-flex">
                <div class="toast-body">
                    {{ msg }}
                </div>
                <button class="btn-close border-0 text-white mr-3" :class="{'bg-success': msgtoast != 'error', 'bg-danger': msgtoast == 'error'}" aria-label="Close" @click="msgtoast = ''">x</button>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    export default {
        name: "bodegasView",
        data() {
            return {
                userData: {},
                loading: false,
                switchView: true,
                listarCiuddad: [],
                listarBodegas: [],
                city: '',
                idCity: '',
                btnconfirmar: '',
                url: "http://apiinventory.eba-zdkhf2sk.us-west-2.elasticbeanstalk.com/v1",
                modaleSwitch: false,
                modalData: {
                    tipos: {
                        1: "Crear Ciudad",
                        2: "Crear Bodega",
                        3: "Editar Bodega"
                    }, /* 1 = Crear ciudad, 2 = Editar bodega, 3 = crear bodega */
                    tipo: '',/* 
                    id: '', */
                    fullDataModal: {}
                },
                msg:"",
                msgtoast: ""
            }
        },
        components: {},
        methods: {
            /* listar Ciudades */
            async conProductos() {
                this.loading = true
                try {
                    const res = await axios.get(`${this.url}/cities`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                    });
                    this.listarCiuddad = res.data
                } catch (error) {
                    console.log(error);
                }
                this.loading = false
            },

            /* Listar Bodegas */
            async lasBodegas(id, myCity) {
                this.loading = true
                this.switchView = false
                this.city = myCity
                try {
                    const res = await axios.get(`${this.url}/cities/${id}/warehouses`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                    });
                    this.listarBodegas = res.data
                } catch (error) {
                    console.log(error);
                    this.switchView = true
                }
                this.idCity = id
                this.loading = false
            },

            /* Crear Ciudad, Crear Bodega o Editar Bodega */
            modalBodega(id, tipo) {
                this.modaleSwitch = true;
                /* this.modalData.id = id */
                this.modalData.tipo = tipo;
                if (tipo == 0) {
                    this.modalData.fullDataModal = {
                        name: "",
                        slug_name: ""
                    }
                }
                if (tipo == 1) {
                    this.modalData.fullDataModal = {
                        name: "",
                        address: "",
                        latitude: "",
                        longitude: "",
                        city: ""
                    }
                }
                if (tipo == 2) {
                    this.modalData.fullDataModal = id
                }
            },

            /* Crear Ciudad */
            async crearCiudad() {
                if (this.modalData.fullDataModal.name != '' && this.modalData.fullDataModal.slug_name != '') {
                    this.loading = true
                    this.modalData.fullDataModal.name = this.modalData.fullDataModal.name.toLowerCase()
                    this.modalData.fullDataModal.name = this.modalData.fullDataModal.name[0].toUpperCase() + this.modalData.fullDataModal.name.slice(1)
                    this.modalData.fullDataModal.slug_name = this.modalData.fullDataModal.slug_name.toUpperCase()
                    try {
                        const res = await axios.post(`${this.url}/cities`, this.modalData.fullDataModal, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + localStorage.getItem('token')
                            }
                        });
                        this.msg = "Ciudad creada correctamente"
                        this.msgtoast = "creado"
                        setTimeout(() => {
                            this.msgtoast = res.data.message;
                            this.msgtoast = ''
                        }, 5000);
                        this.conProductos()
                    } catch (error) {
                        this.msg = "Error al crear la ciudad"
                        this.msgtoast = "error"
                        setTimeout(() => {
                            this.msgtoast = error;
                            this.msgtoast = ''
                        }, 5000);
                    }
                    this.loading = false
                    this.cerrarModal()
                }else {
                    this.msg = "Todos los campos son obligatorios"
                    this.msgtoast = "error"
                    setTimeout(() => {
                        this.msgtoast = ''
                    }, 5000);
                }
                this.cerrarConfirmar ()
            },

            /* Crear Bodega */
            async crearBodega() {
                if (this.modalData.fullDataModal.name != '' && this.modalData.fullDataModal.address != '') {
                    this.loading = true
                    this.modalData.fullDataModal.name = this.modalData.fullDataModal.name.toLowerCase()
                    this.modalData.fullDataModal.name = this.modalData.fullDataModal.name[0].toUpperCase() + this.modalData.fullDataModal.name.slice(1)
                    this.modalData.fullDataModal.address = this.modalData.fullDataModal.address.toLowerCase()
                    this.modalData.fullDataModal.address = this.modalData.fullDataModal.address[0].toUpperCase() + this.modalData.fullDataModal.address.slice(1)

                    if (this.modalData.fullDataModal.latitude == '' || this.modalData.fullDataModal.longitude == '') {
                        if (this.modalData.fullDataModal.latitude == '') {
                            this.modalData.fullDataModal.latitude = null
                        }
                        if (this.modalData.fullDataModal.longitude == '') {
                            this.modalData.fullDataModal.longitude = null
                        }
                    }

                    try {
                        const res = await axios.post(`${this.url}/cities/${this.idCity}/warehouses`, this.modalData.fullDataModal, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + localStorage.getItem('token')
                            }
                        });
                        this.msg = "Bodega creada correctamente"
                        this.msgtoast = "creado"
                        setTimeout(() => {
                            this.msgtoast = res.data.message;
                            this.msgtoast = ''
                        }, 5000);
                        this.lasBodegas(this.idCity, this.myCity)
                    } catch (error) {
                        this.msg = "Error al crear la bodega"
                        this.msgtoast = "error"
                        setTimeout(() => {
                            this.msgtoast = error;
                            this.msgtoast = ''
                        }, 5000);
                    }
                    this.loading = false
                    this.cerrarModal()
                }else {
                    this.msg = "Todos los campos son obligatorios"
                    this.msgtoast = "error"
                    setTimeout(() => {
                        this.msgtoast = ''
                    }, 5000);
                }
                this.cerrarConfirmar ()
            },

            cerrarConfirmar () {
                this.btnconfirmar = '';
            },

            confirmarbtn(id, text) {
                this.btnconfirmar = {};
                this.btnconfirmar.title = text
                if (text == 'eliminar') {
                    this.btnconfirmar.id = id
                }
            },

            /* Editar Bodega */
            async guardar() {
                if (this.modalData.fullDataModal.name != '' && this.modalData.fullDataModal.address != '' && this.modalData.fullDataModal.latitude != '' && this.modalData.fullDataModal.longitude != '') {
                    this.loading = true
                    this.modalData.fullDataModal.name = this.modalData.fullDataModal.name.toLowerCase()
                    this.modalData.fullDataModal.name = this.modalData.fullDataModal.name[0].toUpperCase() + this.modalData.fullDataModal.name.slice(1)
                    if (this.modalData.fullDataModal.address != "") {
                        this.modalData.fullDataModal.address = this.modalData.fullDataModal.address.toLowerCase()
                        this.modalData.fullDataModal.address = this.modalData.fullDataModal.address[0].toUpperCase() + this.modalData.fullDataModal.address.slice(1)
                    }
                    try {
                        const res = await axios.put(`${this.url}/cities/${this.idCity}/warehouses/${this.modalData.fullDataModal.id}`, this.modalData.fullDataModal, {
                            method: 'PUT',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + localStorage.getItem('token')
                            }
                        });
                        this.msg = "Bodega editada correctamente"
                        this.msgtoast = "editado"
                        setTimeout(() => {
                            this.msgtoast = res.data.message;
                            this.msgtoast = ''
                        }, 5000);
                        this.lasBodegas(this.idCity, this.myCity)

                    } catch (error) {
                        this.msg = "Error al editar la bodega"
                        this.msgtoast = "error"
                        setTimeout(() => {
                            this.msgtoast = error;
                            this.msgtoast = ''
                        }, 5000);
                    }
                    this.loading = false
                    this.cerrarModal()
                }else {
                    this.msg = "Todos los campos son obligatorios"
                    this.msgtoast = "error"
                    setTimeout(() => {
                        this.msgtoast = ''
                    }, 5000);
                }
                this.cerrarConfirmar ()
            },

            /* Eliminar Bodega */
            async eliminarBodega(id) {
                this.loading = true
                const bodyjson = {
                    "is_deleted": true,
                }
                try {
                    const res = await axios.put(`${this.url}/cities/${this.idCity}/warehouses/${id}`, bodyjson, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                    });
                    this.msg = "Bodega eliminada correctamente"
                    this.msgtoast = "eliminado"
                    setTimeout(() => {
                        this.msgtoast = res.data.message;
                        this.msgtoast = ''
                    }, 5000);
                    this.lasBodegas(this.idCity, this.myCity)
                } catch (error) {
                    this.msg = "Error al eliminar la bodega"
                    this.msgtoast = "error"
                    setTimeout(() => {
                        this.msgtoast = error;
                        this.msgtoast = ''
                    }, 5000);
                }
                this.loading = false
                this.cerrarModal()
            },

            /* Cerrar Modal */
            cerrarModal() {
                this.modaleSwitch = false
            },

        },
        mounted() {
            this.userData = JSON.parse(localStorage.getItem("userData"));
            if (this.userData.user != 'superuser') {
                this.$router.push('/');
            }

            this.conProductos();
        },
    }
</script>

<style scoped>
    .btnCity {
        border-radius: 25px;
        box-shadow: 0 0 15px 1px rgb(0 0 0 / 20%);
        cursor: pointer;
        transition: transform 0.1s;
    }

    .btnCity:hover {
        transform: scale(1.03);
        transition: transform 0.2s;
    }

    .targetas {
        border: 1px solid rgba(0, 0, 0, .125);
        border-radius: .25rem;
        box-shadow: 0 0 15px 1px rgb(0, 0, 0, 0.15);
        min-width: 200px;
        transition: box-shadow 0.1s;
    }

    .targetas:hover {
        box-shadow: 0 0 15px 1px rgb(0, 0, 0, 0.2);
        transition: box-shadow 0.2s;
    }

    .btnconfirmar {
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        width: 100vw;
        height: 100vh;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1500;
    }
    .btnconfirmar div {
        background-color: white;
        border-radius: 10px;
        padding: 25px;
    }
</style>