<template>
  <div>
    <nav-aliada v-if="currentRouteName != 'Login'"/>
    <router-view />
  </div>
</template>

<script>
import NavAliada from '@/components/NavAliada.vue';
  export default {
    name: "App",
    components: { NavAliada },
    methods: {
    },
    computed: {
      currentRouteName() {
          return this.$route.name;
      }
  }
  }
</script>