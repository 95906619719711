<template>
    <div id="body-pd" class="body oculto-impresion">
        <div class="header" id="header">
            <div class="header_toggle"> <i class='bx bx-menu' id="header-toggle"></i> </div>
            <router-link to="/" class="navbar-brand">
                <img src="../assets/logoHeader.png" alt="logo" width="70">
            </router-link>

            <div class="header_img">
                <img :src="avatraUser" alt="">
            </div>
        </div>
        <div class="l-navbar" id="nav-bar">
            <nav class="nav">
                <div>
                    <a class="nav_logo">
                        <i class='bx bx-layer nav_logo-icon'></i>
                        <span class="nav_logo-name">{{ headerName.name }} {{ headerName.last_name }}</span>
                    </a>
                    <div class="nav_list">
                        <router-link class="nav_link" to="/">
                            <i class='bx bx-grid-alt nav_icon'></i>
                            <span class="nav_name">Dashboard</span>
                        </router-link>
                        <router-link class="nav_link" to="/productos">
                            <i class='bx bx-shopping-bag nav_icon'></i>
                            <span class="nav_name">Productos</span>
                        </router-link>
                        <router-link class="nav_link" v-if="userData.user == 'superuser' || userData.user == 'staff'" to="/inventario">
                            <i class='bx bx-notepad nav_icon'></i>
                            <span class="nav_name">Inventario</span>
                        </router-link>
                        <router-link class="nav_link" v-if="userData.user == 'superuser' || userData.user == 'staff'" to="/clientes">
                            <i class='bx bxs-user-detail nav_icon' ></i>
                            <span class="nav_name">Clientes</span>
                        </router-link>
                        <router-link class="nav_link" v-if="userData.user == 'superuser'" to="/usuarios">
                            <i class='bx bxs-user'></i>
                            <span class="nav_name">Usuarios</span>
                        </router-link>
                        <router-link class="nav_link" v-if="userData.user == 'superuser'" to="/bodegas">
                            <i class='bx bx-store-alt nav_icon'></i>
                            <span class="nav_name">Bodegas</span>
                        </router-link>
                    </div>
                </div>
                <a class="nav_link pointer" @click="cerrarSesion()">
                    <i class='bx bx-log-out nav_icon'></i>
                    <span class="nav_name">SignOut</span>
                </a>
            </nav>
        </div>
    </div>
</template>

<script>
    import decode from "jwt-decode";
    export default {
        name: 'NavAliada',
        data() {
            return {
                /* Data user */
                userData:"",
                /* Fin data user */
                headerName: [],
                avatraUser: "",
            }
        },
        methods: {
            cerrarSesion() {
                localStorage.removeItem("token");
                localStorage.removeItem("userData");
                this.$router.push({name: "Login"});
            },
        },
        mounted() {
            this.userData = JSON.parse(localStorage.getItem("userData"));

            if (localStorage.getItem("token") == null) {
                this.$router.push({name: "Login"});
            } else {
                this.headerName = decode(localStorage.getItem("token"));
            }
            if (this.headerName.avatar == null) {
                this.avatraUser = "https://s3.amazonaws.com/n.mobile.comidaenlau/missing/profile.jpg";
            } else {
                this.avatraUser = this.headerName.avatar;
            }

            /* Menu desplegable */
            const showNavbar = (toggleId, navId, bodyId, headerId) => {
                const toggle = document.getElementById(toggleId),
                    nav = document.getElementById(navId),
                    bodypd = document.getElementById(bodyId),
                    headerpd = document.getElementById(headerId);

                if (toggle && nav && bodypd && headerpd) {
                    toggle.addEventListener('click', () => {
                        nav.classList.toggle('show')
                        toggle.classList.toggle('bx-x')
                        bodypd.classList.toggle('body-pd')
                        headerpd.classList.toggle('body-pd')
                    })
                }
            }

            showNavbar('header-toggle', 'nav-bar', 'body-pd', 'header')

            const linkColor = document.querySelectorAll('.nav_link')

            function colorLink() {
                if (linkColor) {
                    linkColor.forEach(l => l.classList.remove('active'))
                }
                this.classList.add('active')
            }
            linkColor.forEach(l => l.addEventListener('click', colorLink))
            /* Fin menu desplegable */
        }
    }
</script>

<style scoped>

    *,
    ::before,
    ::after {
        box-sizing: border-box
    }

    .body {
        margin: 4rem 0 0 0;
        padding-left: 18px;
    }

    a {
        text-decoration: none
    }

    .header {
        width: 100%;
        height: 3rem;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 1rem;
        background-color: #F7F6FB;
        z-index: 100;
        transition: .5s;
        box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
        line-height: 1.5;
    }

    .header_toggle {
        color: #3a18e5;
        font-size: 1.5rem;
        cursor: pointer
    }

    .header_img {
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        border-radius: 50%;
        overflow: hidden
    }

    .header_img img {
        width: 40px
    }

    .l-navbar {
        position: fixed;
        top: 0;
        left: -30%;
        width: 68px;
        height: 100vh;
        background-color: #3a18e5;
        padding: .5rem 1rem 0 0;
        transition: .5s;
        z-index: 100;
    }

    .nav {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden
    }

    .nav_logo,
    .nav_link {
        display: grid;
        grid-template-columns: max-content max-content;
        align-items: center;
        column-gap: 1rem;
        padding: .5rem 0 .5rem 1.5rem
    }

    .nav_logo {
        margin-bottom: 2rem
    }

    .nav_logo-icon {
        font-size: 1.25rem;
        color: #F7F6FB
    }

    .nav_logo-name {
        color: #F7F6FB;
        font-weight: 700
    }

    .nav_link {
        position: relative;
        color: #AFA5D9;
        margin-bottom: 1.5rem;
        transition: .3s
    }

    .nav_link:hover {
        color: #F7F6FB
    }

    .nav_icon {
        font-size: 1.25rem
    }

    .show {
        left: 0;
    }

    div#nav-bar:hover {
        width: 224px;
    }

    .body-pd {
        padding-left: calc(68px + 1rem)
    }

    .active, .router-link-exact-active {
        color: #F7F6FB
    }

    .active::before {
        content: '';
        position: absolute;
        left: 0;
        width: 2px;
        height: 32px;
        background-color: #F7F6FB
    }

    .height-100 {
        height: 100vh
    }

    @media screen and (min-width: 768px) {
        .body {
            padding-left: calc(68px + 2rem)
        }

        .header {
            height: 4rem;
            padding: 0 2rem 0 calc(68px + 2rem)
        }

        .header_img {
            width: 40px;
            height: 40px
        }

        .header_img img {
            width: 45px
        }

        .l-navbar {
            left: 0;
            padding: 1rem 1rem 0 0
        }

        .show {
            width: 224px
        }

        .body-pd {
            padding-left: 256px
        }
    }
</style>