<template>
    <div class="container text-center mb-5 mt-5 py-5"  id="conten">
        <listar-variado :fullDataParam="fullDataParam" />
		
		<!-- Modales -->
        <div v-if="fullDataParam.modal.estadoModal" class="modal" tabindex="-1">
            <div class="modal-dialog overflow-auto">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ Object.values(fullDataParam.modal.tiposModal)[fullDataParam.modal.tipo] }}</h5>
                        <button type="button" class="btn-close border-0" data-bs-dismiss="modal" aria-label="Close" @click="cerrarModal()"><i class='bx bx-x'></i></button>
                    </div>

                    <!-- Crear Usuario -->
                    <div v-if="fullDataParam.modal.tipo == 0" class="modal-body">
                        <div class="form-group text-left">
                            <label for="name">Nombre de usuario</label>
                            <input type="text" class="form-control" id="name" v-model="fullDataParam.modal.dataModal.username">
                        </div>
                        <div class="form-group text-left">
                            <label for="email">Email</label>
                            <input type="email" class="form-control" id="email" v-model="fullDataParam.modal.dataModal.email">
                        </div>
                        <div class="form-group text-left">
                            <label for="password">Contraseña</label>
                            <input type="password" class="form-control" id="password" v-model="fullDataParam.modal.dataModal.password">
                        </div>
                        <div class="form-group text-left">
                            <label for="password2">Verificar Contraseña</label>
                            <input type="password2" class="form-control" id="password2" v-model="fullDataParam.modal.dataModal.password2">
                        </div>
                        <div class="form-group text-left">
                            <label for="nombre">Nombre</label>
                            <input type="text" class="form-control" id="nombre" v-model="fullDataParam.modal.dataModal.first_name">
                        </div>
                        <div class="form-group text-left">
                            <label for="apellido">Apellido</label>
                            <input type="text" class="form-control" id="apellido" v-model="fullDataParam.modal.dataModal.Last_name">
                        </div>
                    </div>

                    <!-- Editar Usuario -->
                    <div v-else-if="fullDataParam.modal.tipo == 1" class="modal-body">
                        <div class="form-group text-left">
                            <label for="name">Nombre de usuario</label>
                            <input type="text" class="form-control" id="name" v-model="fullDataParam.modal.dataModal.username" disabled>
                        </div>
                        <div class="form-group text-left">
                            <label for="nombre">Nombre</label>
                            <input type="text" class="form-control" id="nombre" v-model="fullDataParam.modal.dataModal.first_name">
                        </div>
                        <div class="form-group text-left">
                            <label for="apellido">Apellido</label>
                            <input type="text" class="form-control" id="apellido" v-model="fullDataParam.modal.dataModal.Last_name">
                        </div>
                        <div class="form-group text-left">
                            <fieldset>
                                <p>Tipo de Usuario</p>
                                <label>
                                    <input type="radio" id="standarUser" name="userTipo" value="standar" v-model="tipoUser">
                                Standar</label>
                                <label class="ml-3">
                                    <input type="radio" id="staff" name="userTipo" value="staff" v-model="tipoUser">
                                Staff</label>
                                <label class="ml-3">
                                    <input type="radio" id="superuser" name="userTipo" value="superuser" v-model="tipoUser">
                                Superuser</label>
                            </fieldset>
                        </div>
                        <!-- <div class="form-group text-left" v-if="tipoUser == 'standar'">
                            <label for="custumer">Proveedor</label>
                            <select class="form-control" v-model="fullDataParam.modal.dataModal.customer_id">
                                <option value="null" id="custumer" disabled>Seleccionar</option>
                                <option v-for="prove in provedores.results" :key="prove.id" :value="prove.id">
                                    {{ prove.name }}
                                </option>
                            </select>
                        </div> -->
                        <div class="form-group text-left" v-if="tipoUser == 'staff'">
                            <label for="bodegas">Bodega</label>
                            <select class="form-control" v-model="bodegas.id">
                                <option value="undefined" disabled>Bodega</option>
                                <optgroup v-for="(city, i) in bodegas" :key="i" :label="city.name">
                                    <option v-for="(bodega, o) in city.warehouses" :key="o" :value="bodega.id">
                                        {{ bodega.name }}</option>
                                </optgroup>
                            </select>
                        </div>
                    </div>


                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="cerrarModal()">Cerrar</button>
                        <button v-if="fullDataParam.modal.tipo == 0" type="button" class="btn btn-primary" @click="crearUsuario()">Crear</button>
                        <button v-if="fullDataParam.modal.tipo == 1" type="button" class="btn btn-primary" @click="guardar()">Guardar</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Notificacion toast -->
        <div v-if="msgtoast != ''" class="align-items-center text-white border-0 position-fixed" :class="{'bg-success': msgtoast != 'error', 'bg-danger': msgtoast == 'error'}" style="right:35px; bottom:35px; z-index: 1060;" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="d-flex">
                <div class="toast-body">
                    {{ msg }}
                </div>
                <button class="btn-close border-0 text-white mr-3" :class="{'bg-success': msgtoast != 'error', 'bg-danger': msgtoast == 'error'}" aria-label="Close" @click="msgtoast = ''">x</button>
            </div>
        </div>

    </div>
</template>

<script>
    import listarVariado from '@/components/listarVariado.vue';
    const url = "http://apiinventory.eba-zdkhf2sk.us-west-2.elasticbeanstalk.com";
    import axios from 'axios';
    export default {
        name: "usuariosView",
        data() {
            return {
                userData: {},
				msgtoast: '',
				msg: '',
                provedores: {},
                bodegas: {
                    id: '',
                    results: []
                },
                tipoUser: '',
                fullDataParam: {
					url: "http://apiinventory.eba-zdkhf2sk.us-west-2.elasticbeanstalk.com/v1/users",
					botonCrear: "Crear Usuario",/* El texto del boton superior */
					filtro: 0,/* El parametro de la busqueda, 0 para que aparesca filtrar*/
					filtrar: [ /* Data para filtrar la busqueda */
                        {username: "Correo"},
                        {first_name: "Nombre"},
						{ID: 'ID'}
					],
					resulGeneral: {
						results: "", /* pocicion de los datos a recorrer en caso de no requerir enviar "" */
						resulComplete: [],/* Si el resultado trae mas datos en la consulta mas la tabla */
						resulTable: [] /* Lo que se va a listar en la tabla */
					},
					tableParams: [
						{Username: "", titleName: "Nombre de Usuario"},
						{First_name: "nomovil", titleName: "Nombres"},
						{Last_name: "notable", titleName: "Apellidos"},
						{Customer_id: "notable", titleName: "ID"},
					],
					modal: {/* Todos los datos de la modal */
						estadoModal: false,
						tiposModal: {
							0: "Crear Usuario",
							1: "Editar Usuario"
						}, /* 0 = Crear Usuario, 1 = Editar Usuario*/
						tipo: "",
						dataModal: {
							/* data agregada manualmente (lo traera desde el componente) */
							username: "",
							email: "",
							password: "",
							password2: "",
							first_name: "",
							Last_name: "",
							customer_id: "",
						},
					},
				}
            }
        },
        components: {
            listarVariado
        },
		methods: {
			cerrarModal() {
				this.fullDataParam.modal.estadoModal = false;
				document.getElementsByTagName('body')[0].style.overflow = 'auto'
			},
            async conbodegas() {
                if (this.userData.user == 'superuser') {
                    let res = await axios.get(`${url}/v1/cities`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                    });
                    this.bodegas = res.data;
                }
            },

            async guardar() {
                if (this.fullDataParam.modal.dataModal.username != '' && this.tipoUser != '') {
                    this.loading = true
                    /* if (this.fullDataParam.modal.dataModal.first_name != "" || this.fullDataParam.modal.dataModal.first_name != undefined) {
                        this.fullDataParam.modal.dataModal.first_name = this.fullDataParam.modal.dataModal.first_name.toLowerCase();
                        this.fullDataParam.modal.dataModal.first_name = this.fullDataParam.modal.dataModal.first_name[0].toUpperCase() + this.fullDataParam.modal.dataModal.first_name.slice(1)
                    }else  */if (this.fullDataParam.modal.dataModal.first_name == undefined) {
                        this.fullDataParam.modal.dataModal.first_name = ""
                    }

                    /* if (this.fullDataParam.modal.dataModal.Last_name != "" || this.fullDataParam.modal.dataModal.Last_name != undefined) {
                        this.fullDataParam.modal.dataModal.Last_name = this.fullDataParam.modal.dataModal.Last_name.toLowerCase();
                        this.fullDataParam.modal.dataModal.Last_name = this.fullDataParam.modal.dataModal.Last_name[0].toUpperCase() + this.fullDataParam.modal.dataModal.Last_name.slice(1)
                    }else  */if (this.fullDataParam.modal.dataModal.Last_name == undefined) {
                        this.fullDataParam.modal.dataModal.Last_name = ""
                    }

                    let fullData = {
                        first_name: this.fullDataParam.modal.dataModal.first_name,
                        last_name: this.fullDataParam.modal.dataModal.Last_name,
                    }

                    if (this.tipoUser == "superuser" || this.tipoUser == "staff") {
                        console.log(this.tipoUser)
                        if (this.tipoUser == "superuser") {
                            fullData.is_superuser = true
                        } else {
                            if (this.bodegas.id != undefined) {
                                fullData.warehouse_id = this.bodegas.id
                            }
                        }
                        fullData.is_staff = true
                    } else {
                        fullData.is_superuser = false;
                        fullData.is_staff = false
                    }

                    console.log(fullData)

                    try {
                        const res = await axios.patch(`${this.fullDataParam.url}/${this.fullDataParam.modal.dataModal.id}`, fullData, {
                            method: 'PATCH',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + localStorage.getItem('token')
                            }
                        });
                        this.msg = "Cliente editado correctamente"
                        this.msgtoast = "completado"
                        setTimeout(() => {
                            this.msgtoast = res.data.message;
                            this.msgtoast = ''
                            this.cerrarModal()
                        }, 3000);

                    } catch (error) {
                        this.msg = "Error al crear el cliente"
                        this.msgtoast = "error"
                        setTimeout(() => {
                            this.msgtoast = error;
                            this.msgtoast = ''
                            this.cerrarModal()
                        }, 3000);
                    }
                    this.loading = false
                }else {
                    this.msg = "Todos los campos son obligatorios"
                    this.msgtoast = "error"
                    setTimeout(() => {
                        this.msgtoast = ''
                    }, 5000);
                }
                /* this.cerrarConfirmar () */
            },
		},
        mounted() {
            this.userData = JSON.parse(localStorage.getItem("userData"));
            if (this.userData.user != 'superuser') {
                this.$router.push('/');
            }
            this.conbodegas()
        },
    }
</script>

<style scoped>
</style>