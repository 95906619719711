import { render, staticRenderFns } from "./listadoProductos.vue?vue&type=template&id=bbae4686&scoped=true&"
import script from "./listadoProductos.vue?vue&type=script&lang=js&"
export * from "./listadoProductos.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbae4686",
  null
  
)

export default component.exports