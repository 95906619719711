<template>
    <div class="container text-center mb-5 mt-5 py-5" id="conten">
        <h1 class="mb-3">Nueva Orden</h1>

        <div>
            <h3 class="form-header__heading">Ingresa información de la orden</h3>

            <div class="ordenTabla">
                <div class="orden-movile">
                    <select class="marketing-input marketing-input--floating" :required="true" v-model="costumerOrder.tipoOrden">
                        <option disabled :selected="costumerOrder.tipoOrden == 'tipo'" :value="costumerOrder.tipoOrden">Tipo</option>
                        <option value="entrada">Entrada</option>
                        <option value="salida">Salida</option>
                    </select>
                </div>
                <div class="orden-movile">
                    <label class="marketing-input-wrapper">
                        <span class="marketing-label--in-field marketing-label--floating">N° Orden</span>
                        <input type="text" name="order" id="order" placeholder="N° Orden"  class="marketing-input marketing-input--floating" :required="true" v-model="agregados.numberOrder">
                    </label>
                </div>
                <div class="orden-movile">
                    <label class="marketing-input-wrapper js-is-filled">
                        <span class="marketing-label--in-field marketing-label--floating">Fecha de creación</span>
                        <input type="date" name="order_date" id="order_date" placeholder="Fecha de creacion" class="marketing-input marketing-input--floating"  disabled v-model="agregados.orderDate" asp-format="{0:dd-MM-yyyy}"></label>
                </div>
                <div class="orden-movile">
                    <label class="marketing-input-wrapper">
                        <span class="marketing-label--in-field marketing-label--floating">{{ costumerOrder.name }}</span>
                        <input type="text" name="order_proveedor" id="order_proveedor" :placeholder=" costumerOrder.name" :value="costumerOrder.name" class="marketing-input marketing-input--floating" disabled>
                    </label>
                </div>
                <div class="orden-movile">
                    <label class="marketing-input-wrapper">
                        <span class="marketing-label--in-field marketing-label--floating">Bodega</span>
                        <input type="text" name="order_bodega" id="order_bodega" :placeholder="agregados.bodega" :value="agregados.bodega" class="marketing-input marketing-input--floating" disabled>
                    </label>
                </div>
            </div>
            <div>
                <label class="marketing-input-wrapper">
                    <span class="marketing-label--in-field marketing-label--floating">Descripción</span>
                    <textarea name="shipping_terms" id="shipping_terms" class="marketing-textarea" :placeholder="agregados.description"></textarea>
                </label>
            </div>
            <hr>

            <!-- Agregar Productos -->
            <h3 class="form-header__heading">Ingresar productos</h3>
            <div class="ordenTabla" style="border-top: 1px solid rgba(0, 0, 0, 0.3); padding-top:15px" v-for="(producto, i) in agregados.productos" :key="i">
                <div class="orden-movile">
                    <label class="marketing-input-wrapper">
                        <span class="marketing-label--in-field marketing-label--floating">SKU</span>
                        <input type="number" name="order-sku" placeholder="SKU" class="marketing-input marketing-input--floating" v-model="producto.sku" @keyup="buscarProducto(i)">
                    </label>
                </div>
                <div class="orden-movile">
                    <label class="marketing-input-wrapper">
                        <span class="marketing-label--in-field marketing-label--floating">Nombre</span>
                        <input type="text" name="order-nombre" placeholder="Nombre" class="marketing-input marketing-input--floating" disabled v-model="producto.nombre">
                    </label>
                </div>
                <div class="orden-movile">
                    <label class="marketing-input-wrapper" v-if="producto.perishable">
                        <span class="marketing-label--in-field marketing-label--floating">Lote</span>
                        <input type="text" name="order-lote" placeholder="Lote" class="marketing-input marketing-input--floating" v-model="producto.lote">
                    </label>
                </div>
                <div class="orden-movile">
                    <label class="marketing-input-wrapper js-is-filled" v-if="producto.perishable">
                        <span class="marketing-label--in-field marketing-label--floating">Vencimiento</span>
                        <input type="date" name="order_vencimiento" placeholder="Vencimiento" class="marketing-input marketing-input--floating" v-model="producto.vencimiento">
                    </label>
                </div>
                <div class="orden-movile">
                    <label class="marketing-input-wrapper">
                        <span class="marketing-label--in-field marketing-label--floating">Cantidad</span>
                        <input type="number" name="order-cantidad" placeholder="Cantidad" class="marketing-input marketing-input--floating" min="1" :max="producto.product_qty" v-model="producto.cantidad">
                    </label>
                </div>
                <div>
                    <label style="padding-left: 10px; padding-top: 10px;">
                        <button type="button" class="btn btn-danger btn-circle mb-3" @click="eliminarProducto(i)">
                            <i class='bx bx-x'></i>
                        </button>
                    </label>
                </div>
            </div>
            <hr>

            <!-- Boton Agregar producto a la orden -->
            <div class="ordenTabla">
                <div class="text-left">
                    <button class="btn btn-primary" @click="agregarproducto">Agregar Producto</button>
                </div>
            </div>
        </div>

        <div class="mt-3" style="display:flex; justify-content: flex-end;">
            <div class="orden-end">
                <router-link class="btn btn-secondary" to="/inventario">Cancelar</router-link>
            </div>
            <div class="orden-end">
                <button class="btn btn-info" @click="previewOrden">Preview</button>
            </div>
        </div>

        <modalNuevaOrden v-if="preview" :agregados="agregados" @cerrar="cerrarModal"/>
    </div>
</template>

<script>
    import modalNuevaOrden from '../components/inventarioComponente/modalNuevaOrden.vue'
    import axios from 'axios'
    export default {
        name: 'nuevaOrdenView',
        components: {
            modalNuevaOrden
        },
        data() {
            return {
                selec:'',
                costumerOrder: [],
                preview: false,
                agregados: {
                    tipoOrden: 'tipo',
                    numberOrder: '',
                    orderDate: '',
                    description: 'Descripción',
                    idCostumer: '',
                    nameCostumer: '',
                    bodega: 'Bodega Principal',
                    productos: [{
                        sku: '',
                        nombre: '',
                        lote: '',
                        vencimiento: '',
                        cantidad: '',
                        perishable: true,
                        product_qty: '',
                    }],
                },
            }
        },
        methods: {
            proveedor(){
                if (this.$route.params.id == 0 || this.$route.params.id == null) {
                    return this.$router.push({name: "Inventario"});
                } else {
                    this.costumerOrder = {id: this.$route.params.id, name: this.$route.params.name};
                }
                /* let fecha = new Date().toLocaleDateString().split('T')[0]; */
                let dia = new Date().getDay() + 1; 
                let mes = new Date().getMonth() + 1;
                let ano= new Date().getFullYear();
                dia = dia.toString();
                mes = mes.toString();

                if (dia.length == 1) {
                    dia = "0" + dia
                }
                if (mes.length == 1) {
                    mes = "0" + mes
                }

                /* this.agregados.orderDate = fecha.slice(5, 10) + "-" + fecha.slice(0, 5).padStart(2, '0') + "-" + fecha.slice(0, 2).padStart(2, '0'); */
                this.agregados.orderDate = ano + "-" + mes + "-" + dia;
            },
            previewOrden(){
                let confirmado = true;
                for (let index = 0; index < this.agregados.productos.length; index++) {
                    console.log(this.agregados.productos[index].cantidad)
                    if (this.agregados.productos[index].sku == '' || this.agregados.productos[index].cantidad == '') {
                        confirmado = false;
                        alert("Debe ingresar un SKU y la cantidad del producto");
                        break;
                    }
                }
                if (confirmado) {
                    this.agregados.idCostumer = this.costumerOrder.id;
                    this.agregados.nameCostumer = this.costumerOrder.name;
                    this.preview = true;
                }
            },
            async buscarProducto(i){
                try {
                    const res = await axios.get(`http://apiinventory.eba-zdkhf2sk.us-west-2.elasticbeanstalk.com/v1/products?customer=${this.agregados.idCostumer}&sku=${this.agregados.productos[i].sku}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                    });
                    this.agregados.productos[i].nombre = res.data.results[0].name;
                    this.agregados.productos[i].perishable = res.data.results[0].perishable;
                } catch (error) {
                    /* console.log(error); */
                }
            },
            eliminarProducto(key){
                if (this.agregados.productos.length > 1) {
                    this.agregados.productos.splice(key, 1);
                } else {
                    this.agregados.productos = [{
                        sku: '',
                        nombre: '',
                        lote: '',
                        vencimiento: '',
                        cantidad: '',
                        perishable: true,
                    }];
                }
            },
            agregarproducto(){
                this.agregados.productos.push({
                    sku: '',
                    nombre: '',
                    lote: '',
                    vencimiento: '',
                    cantidad: '',
                    perishable: true,
                });
            },
            cerrarModal(i){
                this.preview = i;
            }
        },
        mounted() {
            this.proveedor()
        }/* ,
        computed: {
            ano: function(){
                console.log(new Date().getFullYear())
                return new Date().getFullYear();
            }
        } */
    }
</script>

<style scoped>
    h3 {
        text-align: left;
        font-size: large;
    }
    .ordenTabla{
        display: flex;
        justify-content: space-between;
    }

    .orden-movile {
        width: 19%;
    }

    .orden-end {
        margin-left: 15px;
    }

    .marketing-input-wrapper {
        position: relative;
        display: block;
    }

    .marketing-label--floating {
        opacity: 0;
    }
    .marketing-label--in-field {
        position: absolute;
        left: 1rem;
        top: 0.5rem;
        font-size: 0.6875em;
        font-weight: 400;
        pointer-events: none;
        z-index: 10;
    }

    .marketing-input, .marketing-textarea, .marketing-select {
        display: inline-block;
        height: 3.2em;
        width: 100%;
        /* margin: 0 0 15px; */
        padding: 1em 0.9375em;
        color: #212326;
        font-size: 1em;
        box-shadow: 0 0 0 1px #6b7177;
        border: 1px solid transparent;
        border-radius: 4px;
        background-color: #ffffff;
        transition: padding 150ms;
    }

    .marketing-input:focus-visible, .marketing-textarea:focus-visible, .marketing-select:focus-visible {
        box-shadow: 0 0 0 1px #0080ff;
        border: 1px solid #0080ff;
    }

    .js-is-filled .marketing-input--floating {
        padding-top: 1.5em;
        padding-bottom: 0.5em;
    }
    .js-is-filled .marketing-label--floating {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    .btn-circle[data-v-64ab2e00] {
        width: 30px;
        height: 30px;
        padding: 6px 0px;
        border-radius: 15px;
        font-size: 18px;
        line-height: 0.5;
    }
    @media screen and (max-width: 500px) {
        .ordenTabla{
            display: initial;
            justify-content: none;
        }
        .orden-movile {
            width: 100%;
        }
    }
</style>