<template>
    <div>

        <!-- Ver todos los productos -->
        <div v-if="this.userData.user != 'standard' && selected != 0" @change="conProductos">
            <!-- Selector radial para cambiar la url de productos -->
            <input class="mr-2" type="radio" id="one" value="One" v-model="picked" />
            <label class="mr-4" for="one">Disponible en la bodega</label>

            <input v-if="this.userData.user != 'staff'" class="mr-2" type="radio" id="two" value="Two" v-model="picked" />
            <label v-if="this.userData.user != 'staff'" class="mr-4" for="two">Disponible en todas las bodegas</label>

            <input class="mr-2" type="radio" id="three" value="Three" v-model="picked" />
            <label for="three">Todos los productos</label>
        </div>


        <!-- Boton para crear productos -->
        <div class="mb-5 mt-5" v-if="this.userData.user == 'superuser' && this.selected != 0">
            <button type="button" class="btn btn-primary" @click="modalProduc(false)">Crear nuevo producto</button>
        </div>

        <!-- Cargando... -->
        <div v-if="loading">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>

        <!-- buscar -->
        <div class="form-group mt-4" v-if="selected != 0">
            <select id="filtro" class="form-control d-inline-block w-auto" v-model="filtro">
                <option value="0">Filtrar</option>
                <option value="name">Nombre</option>
                <option value="sku">SKU</option>
            </select>
            <!-- imput para buscar -->
            <input v-if="filtro != 'sku'" type="text" placeholder="Buscar"
                class="form-control d-inline-block w-auto ml-2" v-model="key" @keyup="buscarProduc">
            <input v-else type="number" placeholder="Buscar" class="form-control d-inline-block w-auto ml-2"
                v-model="key" @keyup="buscarProduc">
        </div>

        <table v-if="selected != 0" @change="conProductos"
            class="table-sm table-hover table-bordered sortable center text-center">
            <thead>
                <tr>
                    <th class="notable">SKU</th>
                    <th>Nombre</th>
                    <!-- <th class="nomovil">Descripción</th> -->
                    <!-- <th class="notable">Código de barras</th> -->
                    <th class="nomovil">Stock total</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(produc, i) in producs.results" :key="i" class="pointer" @click="modalProduc(i)">
                    <td class="notable text-center">{{ produc.sku }}</td>
                    <td class="text-center">{{ produc.name }}</td>
                    <td class="nomovil text-center" >{{ totalStock(produc.available) }}</td>
                </tr>

            </tbody>
            <tfoot>
                <!-- botones adelantar pagina y atrasar pagina -->
                <tr>
                    <td colspan="6">
                        <nav aria-label="Page navigation example">
                            <ul class="pagination justify-content-center">
                                <li class="page-item" v-if="pag > 1">
                                    <a class="page-link pointer" aria-label="Previous" @click.prevent="navPage(1)">
                                        <span aria-hidden="true">&laquo;</span>
                                        <span class="sr-only">Primera</span>
                                    </a>
                                </li>
                                <li class="page-item" v-for="(p, i) in combertirArrye" :key="i"
                                    :class="{'active': p == pag}">
                                    <a class="page-link pointer" @click.prevent="navPage(p)"
                                        v-if="p == pag -1 || p == pag || p == pag + 1">{{ p }}</a>
                                </li>
                                <li class="page-item" v-if="pag < combertirArrye.length">
                                    <a class="page-link pointer" aria-label="Next"
                                        @click.prevent="navPage(combertirArrye.length)">
                                        <span aria-hidden="true">&raquo;</span>
                                        <span class="sr-only">Ultima</span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </td>
                </tr>
            </tfoot>
        </table>

        <!-- Modal para crear productos -->
        <modal-productos :producModal="producModal" :modal="modal" :url="url" @cerrar="cerrarModal" />
    </div>
</template>

<script>
    import axios from 'axios';
    import modalProductos from './modalProductos.vue';
    /* import VueBarcode from 'vue-barcode'; */
    export default {
        components: { modalProductos, /* 'barcode': VueBarcode */ },
        name: 'listadoProductos',
        props: {
            selected: undefined,
            producs: undefined,
        },
        data() {
            return {
                userData: {},
                NUM_RESULTS: 20,
                pag: 1,
                key: "",
                filtro: "0",
                picked: "One",
                loading: false,
                modal: false,
                producModal: {
                    pey: false,
                    id: 0,
                    sku: "",
                    name: "",
                    description: "",
                    ean: "",
                    perishable: false,
                    customer: this.selected
                },
                url: "http://apiinventory.eba-zdkhf2sk.us-west-2.elasticbeanstalk.com/v1",
            }
        },
        methods: {
            cerrarModal(id) {
                this.modal = id;
                this.producModal ={
                    sku: '',
                    name: '',
                    description: '',
                    ean: '',
                    perishable: '',
                    pey: false,
                    customer: this.selected
                };
                this.conProductos();
            },
            /* busqueda de productos*/
            async buscarProduc() {
                if (this.filtro == "0" || this.filtro == 0) {
                    this.filtro = "name"
                }
                this.loading = true
                try {
                    const res = await axios.get(`${this.url}/customers/${this.selected}/products?${this.filtro}=${this.key}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                    });
                    this.$emit('actualizaLista', res.data)
                } catch (error) {
                    console.log(error);
                }
                this.loading = false
            },

            /* consultar lista de productos*/
            async conProductos() {
                this.loading = true
                let urlUser ="";

                if(this.picked == "Two"){
                    urlUser = `${this.url}/customers/${this.selected}/available_products?warehouse_filter=false`;
                } else if(this.picked == "Three") {
                    urlUser = `${this.url}/customers/${this.selected}/products?warehouse_filter=true`;
                }else {
                    if(this.userData.user == 'standard'){
                        urlUser = `${this.url}/customers/${this.userData.customer}/available_products`;
                    }else if (this.userData.user != 'standard') {
                        urlUser = `${this.url}/customers/${this.selected}/available_products?warehouse_filter=true`;
                    }
                }

                try {
                    const res = await axios.get(urlUser, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                    });
                    this.$emit('actualizaLista', res.data)
                } catch (error) {
                    console.log(error);
                }
                this.loading = false
            },

            /* Paginador */
            async navPage(page) {
                if(page != 0){
                    this.loading = true
                    try {
                        const res = await axios.get(`http://apiinventory.eba-zdkhf2sk.us-west-2.elasticbeanstalk.com/v1/customers/1/products?page=${page}`, {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + localStorage.getItem('token')
                            }
                        });
                        this.$emit('actualizaLista', res.data)
                        this.pag = page
                    } catch (error) {
                        console.log(error.data);
                    }
                    this.loading = false
                }
            },
            
            /* Ver detalle o crear producto */
            modalProduc(id) {
                if (id != false || id === 0) {
                    this.producModal = this.producs.results[id];
                }
                this.modal = true
                document.getElementsByTagName('body')[0].style.overflow = 'hidden'
            },

            /* Sumar Stock */
            totalStock(a) {
                let totalStk= 0;
                for (let i = 0; i < a.length; i++) {
                    totalStk += a[i].stock;
                }
                return totalStk
            },

        },
        mounted() {
            this.userData = JSON.parse(localStorage.getItem("userData"));
            if (this.userData.user == 'standard') {
                this.conProductos()
            }
        },
        watch: {
            selected() {
                this.producModal.customer = this.selected,
                this.conProductos()
            }
        },
        computed: {
            combertirArrye: function() {
                let a = Math.ceil(this.producs.count / this.NUM_RESULTS);
                let i = [a];
                for (let x = 0; x < a; x++) {
                    i[x] = x + 1;
                }
                return i;
            }
        }
    }
</script>

<style scoped>
</style>