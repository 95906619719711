<template>
    <div  @keydown="foco">
        <p class="font-weight-bold position-absolute d-flex align-items-center" style="margin-top: -40px">
            <i  @click="cerrar" class='bx bx-chevron-left btnAtras'></i>
        </p>

        <!-- Ventana nueva orden o salida -->
        <h1 class="text-primary text-capitalize font-weight-bold mb-4">Nueva {{ newOrderData.title }} para {{ newOrderData.nameCustomer }}</h1>
        <button class="btn btn-primary font-weight-bold px-4 py-2" @click="newProduct = true">Ingresar Producto</button>


        <!-- Lista de productos agrregados -->
        <div class="container mt-5" v-if=" listaAgregados.length != 0">
            <div class="row justify-content-center">
                <div style="width: 100%;">
                    <div class="card">
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th class="font-weight-bold">Producto</th>
                                            
                                            <th class="font-weight-bold">Cantidad</th>
                                            <th class="font-weight-bold"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(lista, i) in listaAgregados" :key="i">
                                            <td class="text-center">{{ lista.name }}</td>
                                            
                                            <td class="text-center">{{ lista.product_qty }}</td>
                                            <td class="text-center">
                                                <i class="bx bx-trash-alt pointer" @click="deleteProductList(i)"></i> <!-- @click="eliminarProducto(i)" -->
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colspan="4" class="text-right pt-4">
                                                <button class="btn btn-success" @click="confirmarGuardarOrden = true">Guardar {{ newOrderData.title }}</button>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Fin Lista de productos agrregados -->

        <!-- Confirmar Guardar Orden -->
        <div v-if="confirmarGuardarOrden" style="background: rgba(0, 0, 0, .5); z-index: 100;" class="position-fixed w-100 h-100 fixed-top d-flex align-items-center justify-content-center">
            <div  style="background: rgba(255, 255, 255);" class="p-3 rounded">
                <div>
                    <label for="remision"></label>
                    <input type="text" class="form-control" name="remision" v-model="remision" placeholder="Remisión">
                </div>
                <div>
                    <label for="descripcion"></label>
                    <textarea name="descripcion" id="" placeholder="Descripción" v-model="descripcionOrden"></textarea>
                </div>
                <div class="mt-3">
                    <button  class="btn btn-secondary mr-2" @click="confirmarGuardarOrden = false">Cancalar</button>
                    <button v-if="remision != '' && descripcionOrden != ''" class="btn btn-success" @click="guardarOrden">Confirmar {{ newOrderData.title }}</button>
                    <button v-else class="btn btn-success" disabled>Confirmar {{ newOrderData.title }}</button>
                </div>
            </div>
        </div>
        <!-- Fin Confirmar Guardar Orden -->


        <!-- Ventana Agregar Producto -->
        <div style="background: rgba(0, 0, 0, .5); z-index: 100;" class="position-fixed w-100 h-100 fixed-top d-flex align-items-center justify-content-center" v-if="newProduct">

            <div style="background: rgba(255, 255, 255, 1); max-width: 600px; max-height: 95%;" class="py-4 px-4 overflow-auto rounded">
                <p class="text-right mb-0">
                    <i class='bx bx-x text-primary btnCerrar pointer' @click="cerrarNewProduct"></i>
                </p>

                <!-- Ingreso por codigo de barras -->
                <div v-if="ingresoManual == false" class="mx-auto">
                    <h4>Ingresar nuevo producto</h4>
                    <hr>
                    <div class="my-4">
                        <div>
                            <h5>Esperando codigo de barras</h5>

                            <div>
                                <svg class="vue-barcode-element" width="266px" height="97px" x="0px" y="0px" viewBox="0 0 266 97" xmlns="http://www.w3.org/2000/svg" version="1.1" style="transform: translate(0,0)"><rect x="0" y="0" width="266" height="97" style="fill:#ffffff;"></rect><g transform="translate(10, 10)" style="fill:#000000;"><rect x="0" y="0" width="4" height="55"></rect><rect x="6" y="0" width="2" height="55"></rect><rect x="12" y="0" width="6" height="55"></rect><rect x="22" y="0" width="8" height="55"></rect><rect x="32" y="0" width="6" height="55"></rect><rect x="40" y="0" width="2" height="55"></rect><rect x="44" y="0" width="4" height="55"></rect><rect x="52" y="0" width="4" height="55"></rect><rect x="60" y="0" width="4" height="55"></rect><rect x="66" y="0" width="4" height="55"></rect><rect x="72" y="0" width="4" height="55"></rect><rect x="80" y="0" width="4" height="55"></rect><rect x="88" y="0" width="4" height="55"></rect><rect x="96" y="0" width="2" height="55"></rect><rect x="104" y="0" width="2" height="55"></rect><rect x="110" y="0" width="4" height="55"></rect><rect x="120" y="0" width="2" height="55"></rect><rect x="126" y="0" width="2" height="55"></rect><rect x="132" y="0" width="2" height="55"></rect><rect x="136" y="0" width="6" height="55"></rect><rect x="144" y="0" width="4" height="55"></rect><rect x="154" y="0" width="6" height="55"></rect><rect x="162" y="0" width="2" height="55"></rect><rect x="166" y="0" width="8" height="55"></rect><rect x="176" y="0" width="4" height="55"></rect><rect x="184" y="0" width="6" height="55"></rect><rect x="194" y="0" width="2" height="55"></rect><rect x="198" y="0" width="2" height="55"></rect><rect x="202" y="0" width="2" height="55"></rect><rect x="208" y="0" width="4" height="55"></rect><rect x="220" y="0" width="4" height="55"></rect><rect x="230" y="0" width="6" height="55"></rect><rect x="238" y="0" width="2" height="55"></rect><rect x="242" y="0" width="4" height="55"></rect></g></svg>
                            </div>

                            <div class="mb-2">
                                <input v-model="dataBarcode" type="text" ref='focusMe' @keypress.enter="barcodeReader">
                                <p v-if="msgBarcode != false" :class="{'text-danger': msgBarcode == 'Error al buscar el producto'}" class="mt-2">{{ msgBarcode }}</p>
                            </div>

                            <!-- Cargando... -->
                            <div v-if="loading" class="mt-5">
                                <div class="spinner-border text-primary" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>

                            <button @click="ingresoManual = true" class="btn btn-secondary">Ingresar manualmente</button>
                        </div>
                    </div>
                    <hr>
                </div>
                <!-- Fin Ingreso por codigo de barras -->

                <!-- Ingreso manual -->
                <div v-if="ingresoManual" class="mx-auto">
                    <h4 v-if="productToAdd.product.length == 0">Ingresar nuevo producto</h4>
                    <h4 v-else>{{  productToAdd.product.name }}</h4>
                    <hr>

                    <!-- Ver Productos disponibles -->
                    <div v-if="productToAdd.product.length == 0">
                        <h5>Productos disponibles</h5>

                        <input type="text" placeholder="Introduce el Nombre" class="form-control d-inline-block w-auto mt-3 border border-primary text-center" v-model="keySearch" @keyup="buscarProduc">

                        <!-- Cargando... -->
                        <div v-if="loading" class="mt-5">
                            <div class="spinner-border text-primary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>

                        <div v-if="listProducts.length != 0" class="bg-light pt-3">
                            <div v-for="(product, i) in listProducts.results" :key="i" class="border border-secondary mb-1 pointer" @click="productToAdd.product = product">
                                <div class="w-50 d-inline-block align-middle px-2 py-1">{{ product.name }}</div>
                                <div class="w-50 d-inline-block align-middle text-right px-2 py-1">{{ product.ean }}</div>
                            </div>
                        </div>

                        <div v-if="listProducts.results != undefined" class="mt-3">
                            {{ listProducts.results.length }} resultados de {{ listProducts.count }}
                        </div>

                    </div>
                    <!-- Fin Ver Productos disponibles -->

                    <div v-if="productToAdd.product.length != 0">
                        <div class="d-inline-block p-2"><b>SKU: {{ productToAdd.product.sku }}</b></div>
                        <div class="d-inline-block p-2"><b>EAN: {{ productToAdd.product.ean }}</b></div>
                        <div class="d-inline-block p-2" v-if="productToAdd.product.perishable"><b>Pereceredo: <span class="text-danger">Sí</span></b></div>
                        <div class="d-inline-block p-2" v-if="productToAdd.product.perishable == false"><b>Pereceredo: <span class="text-success">No</span></b></div>

                        <div>
                            <div v-if="productToAdd.product.perishable && newOrderData.title == 'Orden'">
                                <label for="lote" class="mr-2 mb-0"><b>Lote #</b></label>
                                <input type="text" name="lote" class="form-control d-inline-block w-auto mt-3 border border-primary text-center" v-model="productToAdd.lot_id">
                            </div>
                            <div v-if="newOrderData.title == 'Orden' && productToAdd.product.perishable">
                                <label for="fecha" class="mr-2 mb-0"><b>Fecha de vencimiento</b></label>
                                <input v-if="lotStock" type="date" name="fecha" class="form-control d-inline-block w-auto mt-3 border border-primary text-center" v-model="productToAdd.due_date" disabled>
                                <input v-else type="date" name="fecha" class="form-control d-inline-block w-auto mt-3 border border-primary text-center" v-model="productToAdd.due_date">
                            </div>
                        </div>

                        
                        <div class="mt-3">
                            <label for="cantidad" class="mr-2 mb-0 align-middle"><b>Cantidad</b><br>Disponible {{ totalStock }}</label>
                            <input v-if="newOrderData.title != 'Orden' && totalStock == 0" type="number" name="cantidad" class="form-control d-inline-block w-auto border border-primary text-center align-middle" placeholder="No disponible" disabled>
                            <input v-else-if="newOrderData.title != 'Orden'" type="number" name="cantidad" placeholder="0" min="1" :max="totalStock" class="form-control d-inline-block w-auto border border-primary text-center align-middle" v-model="productToAdd.stock">
                            <input v-else type="number" name="cantidad" placeholder="0" min="1" class="form-control d-inline-block w-auto border border-primary text-center align-middle" v-model="productToAdd.stock">
                        </div>
                    </div>
                    <hr>
                    <!-- botones agregar o cancelar -->
                    <div class="d-flex justify-content-center" v-if="productToAdd.product.length != 0">
                        <button @click="cerrarNewProduct" class="btn btn-secondary mr-2">Cancelar</button>
                        <div v-if="newOrderData.title == 'Orden' && productToAdd.product.perishable && productToAdd.stock != 0 && productToAdd.due_date != '' && productToAdd.lot_id != ''">
                            <button @click="addProduct" class="btn btn-primary" v-if="newOrderData.title == 'Orden' || totalStock != 0">Agregar</button>
                        </div>
                        <div v-else-if="newOrderData.title == 'Orden' && !productToAdd.product.perishable && productToAdd.stock != 0">
                            <button @click="addProduct" class="btn btn-primary" v-if="newOrderData.title == 'Orden' || totalStock != 0">Agregar</button>
                        </div>
                        <div v-else-if="newOrderData.title != 'Orden' && productToAdd.product.perishable && productToAdd.stock != 0">
                            <button @click="addProduct" class="btn btn-primary" v-if="newOrderData.title == 'Orden' || totalStock != 0">Agregar</button>
                        </div>
                        <div v-else>
                            <button @click="addProduct" class="btn btn-primary" v-if="newOrderData.title == 'Orden' || totalStock == 0" disabled>Agregar</button>
                        </div>
                    </div>
                    <!-- Fin botones agregar o cancelar -->
                </div>
                <!-- Ingreso manual -->

            </div>

        </div>
        <!-- Fin Ventana Agregar Producto -->

        <!-- Notificacion toast -->
        <div v-if="msgtoast != ''" class="align-items-center text-white border-0 position-fixed" :class="{'bg-success': msgtoast != 'error', 'bg-danger': msgtoast == 'error'}" style="right:35px; bottom:35px; z-index: 1000;" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="d-flex">
                <div v-if="msgtoast == 'completado'" class="toast-body">
                    Proceso realizado con exito.
                </div>
                <div v-if="msgtoast == 'error'" class="toast-body">
                    Error en el proceso.
                </div>
                <button type="button" class="btn-close border-0 text-white mr-3" :class="{'bg-success': msgtoast != 'error', 'bg-danger': msgtoast == 'error'}" data-bs-dismiss="toast" aria-label="Close" @click="msgtoast = ''">x</button>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    export default {
        name: 'modalNuevaOrden',
        props: [
            'newOrderData',
        ],
        data() {
            return {
                /* Data user */
                userData:"",
                loading: false,
                newProduct: false,
                listaAgregados: [],
                ingresoManual: false,
                productToAdd: {
                    product: [],
                    product_qty: undefined,
                    lot_id: '',
                    due_date: '',
                    stock: 0,
                },
                keySearch: '',
                listProducts: [],
                url: "http://apiinventory.eba-zdkhf2sk.us-west-2.elasticbeanstalk.com/v1",
                msgBarcode: false,
                dataBarcode: '',
                confirmarGuardarOrden: false,
                remision: "",
                descripcionOrden: "",
                msgtoast: "",
            }
        },
        methods: {
            cerrarNewProduct() {
                this.productToAdd = {product: [], stock: 0, lot_id: '', due_date: '',};
                this.ingresoManual = false;
                this.newProduct = false;
                this.keySearch = '';
                this.listProducts = []
                this.dataBarcode = '';
            },
            cerrar() {
                this.$emit('cerrar');
            },

            /* busqueda de productos*/
            async buscarProduc() {
                this.loading = true
                try {
                    const res = await axios.get(`${this.url}/customers/${this.newOrderData.idCustomer}/products?warehouse_filter=true&name=${this.keySearch}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                    });
                    this.listProducts = res.data
                } catch (error) {
                    console.log(error);
                }
                this.loading = false
            },

            /* agregar producto */
            addProduct() {
                let product = {
                    product_id: this.productToAdd.product.id,
                    name: this.productToAdd.product.name,
                    lot_id: this.productToAdd.lot_id,
                    product_qty: this.productToAdd.stock,
                    due_date: this.productToAdd.due_date,
                    }
                this.listaAgregados.push(product)
                this.cerrarNewProduct()
            },
            /* Fin Agregar Producto */

            /* Eliminar producto */
            deleteProductList(index) {
                this.listaAgregados.splice(index, 1)
            },
            /* Fin Eliminar producto */

            /* Asignar fecha */
            setDate(date) {
                this.productToAdd.due_date = date.split('T')[0];
            },
            /* Fin Asignar fecha */

            /* Barcode Reader */
            foco(){
                if (this.$refs.focusMe != undefined) {
                    this.$refs.focusMe.focus()
                }
            },
            async barcodeReader() {                
                this.loading = true

                try {
                    const res = await axios.get(`${this.url}/customers/${this.newOrderData.idCustomer}/products?warehouse_filter=true&ean=${this.dataBarcode}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                    });
                    if (res.data.count == 0) {
                        this.msgBarcode = "Este producto no existe en la base de datos"
                    } else {
                        this.productToAdd.product = res.data.results[0];
                        this.listProducts = res.data;
                        this.dataBarcode = '';
                        this.ingresoManual = true;
                    }
                } catch (error) {
                    this.msgBarcode = error;
                    this.msgBarcode = "Error al buscar el producto"
                }
                this.loading = false
            },
            /* Fin Barcode Reader */

            /* Guardar Orden */
            async guardarOrden() {
                this.loading = true;
                let type = '';
                if (this.newOrderData.title == 'Orden') {
                    type = 'entry'
                } else {
                    type = 'exit'
                }
                let data = [];

                this.listaAgregados.forEach(e => {
                    
                    let lotes = {};
                    if (e.lot_id == '') {
                        lotes = null;
                    } else {
                        lotes = {
                            lot_id: e.lot_id,
                            due_date: e.due_date,
                            product: e.product_id,
                        }
                    }

                    let product = {
                        product: e.product_id,
                        description: "e.description",
                        product_qty: e.product_qty,
                        lot: lotes,
                    }
                    data.push(product)
                });

                let body = {
                    remission: this.remision,
                    description: this.descripcionOrden,
                    customer: this.newOrderData.idCustomer,
                    product_details: data,
                };

                console.log(body)

                try {
                    await axios.post(`${this.url}/warehouses/${this.userData.bodega}/movements/${type}`,  body, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                    });
                    this.confirmarGuardarOrden = true;
                    this.cerrar()
                    this.msgtoast = 'completado';
                    setTimeout(() => {
                        this.msgtoast = '';
                    }, 3000);
                } catch (error) {
                    console.log(error);
                    this.msgtoast = 'error';
                    setTimeout(() => {
                        this.msgtoast = '';
                    }, 3000);
                }
                this.loading = false
            }
        },
        computed: {
            totalStock: function() {
                let total = 0;
                this.productToAdd.product.available.forEach(product => {
                    total += product.stock;
                });
                return total;
            },

            lotStock: function() {
                let igual = false;
                this.listProducts.results.forEach(product => {
                    
                    if (product.available.length != 0) {
                        product.available.forEach(a => {
                            if (a.lot_id == this.productToAdd.lot_id) {
                                this.setDate(a.due_date)
                                igual = true;
                            }
                        });
                    }
                });
                return igual;
            },
        },
        mounted() {
            this.userData = JSON.parse(localStorage.getItem("userData"));
        },
    }
</script>

<style scoped>
.btnCerrar {
    font-size: 26px;
}
textarea{
    display: block;
    width: 100%;
    height: 80px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: rgb(73, 80, 87);
    background-color: rgb(255, 255, 255);
    background-clip: padding-box;
    border: 1px solid rgb(206, 212, 218);
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}
textarea:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}
</style>