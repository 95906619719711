<template>
	<div>
		<!-- Boton para crear -->
		<div v-if="fullData.botonCrear != 'Crear Usuario' && userData.user == 'superuser' || userData.user == 'staff'" class="mb-5 mt-5">
			<button type="button" class="btn btn-primary" @click="modalProduc(false)">{{ fullData.botonCrear }}</button>
		</div>

		<!-- Cargando... -->
		<div v-if="loading">
			<div class="spinner-border text-primary" role="status">
				<span class="sr-only">Loading...</span>
			</div>
		</div>

		<!-- busqueda -->
		<div class="form-group mt-4">
			<select id="filtro" class="form-control d-inline-block w-auto" v-model="fullData.filtro" @change="tipoValroFiltrado()">
				<option value="0" disabled>Filtrar</option>
				<option v-for="(filtra, i) in fullData.filtrar" :key="i" :value="Object.keys(filtra)[0]">
					{{ Object.values(filtra)[0] }}</option>
			</select>
			<!-- imput para buscar -->
			<input :type="tipoVariable" min="0" placeholder="Buscar" class="form-control d-inline-block w-auto ml-2" v-model="keyBusqueda" @keyup="buscarProduc">
		</div>

		<!-- tabla -->
		<table class="table-sm table-hover table-bordered sortable center text-center">
			<thead>
				<tr>
					<th v-for="(table, i) in fullData.tableParams" :key="i" :class="Object.values(table)[0]">
						{{ Object.values(table)[1] }}</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(resul, i) in fullData.resulGeneral.resulTable" :key="i" class="pointer"
					@click="modalProduc(i)">
					<td v-for="(table, e) in fullData.tableParams" :key="e" :class="Object.values(table)[0]">
						<span v-for="(u, o) in resul " :key="o">
							<i v-if=" minusculas(o) == minusculas(Object.keys(table)[0]) ">{{ u }}</i>
						</span>
					</td>
				</tr>

            </tbody>
            <!-- botones adelantar pagina y atrasar pagina -->
            <tfoot>
                <tr>
                    <td colspan="6">
                        <nav aria-label="Page navigation example">
                            <ul class="pagination justify-content-center">
                                <li class="page-item" v-if="pag > 1">
                                    <a class="page-link pointer" aria-label="Previous" @click.prevent="navPage(1)">
                                        <span aria-hidden="true">&laquo;</span>
                                        <span class="sr-only">Primera</span>
                                    </a>
                                </li>
                                <li class="page-item" v-for="(p, i) in combertirArrye" :key="i"
                                    :class="{'active': p == pag}">
                                    <a class="page-link pointer" @click.prevent="navPage(p)"
                                        v-if="p == pag -1 || p == pag || p == pag + 1">{{ p }}</a>
                                </li>
                                <li class="page-item" v-if="pag < combertirArrye.length">
                                    <a class="page-link pointer" aria-label="Next"
                                        @click.prevent="navPage(combertirArrye.length)">
                                        <span aria-hidden="true">&raquo;</span>
                                        <span class="sr-only">Ultima</span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </td>
                </tr>
            </tfoot>
        </table>

		<!-- Modal para crear productos -->
		<!-- <modal-variado :modal="fullData.modal" @cerrar="cerrarModal"/> -->
	</div>
</template>

<script>
	import axios from 'axios';
	/* import modalVariado from './modalVariado.vue'; */
	export default {
		components: {
			/* modalVariado, */
		},
		name: 'listadoProductos',
		props: {
			fullDataParam: undefined,
		},
		data() {
			return {
				userData: {},
				NUM_RESULTS: 20,
				pag: 1,
				keyBusqueda: "",
				tipoVariable: "text",
				loading: false,
				producModal: {
					pey: false,
					id: 0,
					sku: "",
					name: "",
					description: "",
					ean: "",
					perishable: false
				},

				/* Data del padre */
				fullData: {
					url: "",
					botonCrear: "",/* El texto del boton superior */
					filtro: 0,/* El parametro de la busqueda */
					filtrar: [],
					resulGeneral: {
						results: "",
						resulComplete: [],/* Si el resultado trae mas datos en la consulta mas la tabla */
						resulTable: [] /* Lo que se va a listar en la tabla */
					},
					tableParams: [],
					modal: {/* Todos los datos de la modal */
						estadoModal: "",
						tipoModal:"",/* Los principales tipos son Info, crear y edit */
						dataModal: {},
					},
				}
				/* Fin Data del padre */
			}
		},
		methods: {
			/* Busqueda de productos */
			async buscarProduc() {
				this.loading = true
				if (this.fullData.filtro == "0" || this.fullData.filtro == 0) {
					this.fullData.filtro = Object.keys(this.fullData.filtrar[0])[0];
				}
				try {
					const res = await axios.get(
						`${this.fullData.url}?${this.minusculas(this.fullData.filtro)}=${this.keyBusqueda}`, {
							method: 'GET',
							headers: {
								'Content-Type': 'application/json',
								'Authorization': 'Bearer ' + localStorage.getItem('token')
							}
						});
					if (this.fullData.resulGeneral.results != "") {
						this.fullData.resulGeneral.resulComplete = res.data;
						let i = Object.values(res.data)[Object.values(this.fullData.resulGeneral)[0]];
						this.fullData.resulGeneral.resulTable = i;
					} else {
						this.fullData.resulGeneral.resulTable = res.data;
					}
				} catch (error) {
					console.log(error);
				}
				this.loading = false
			},
			/* Fin Busqueda de productos */

			/* Consulta de listados */
			async conProductos() {
				this.loading = true
				try {
					const res = await axios.get(`${this.fullData.url}`, {
						method: 'GET',
						headers: {
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + localStorage.getItem('token')
						}
					});
					if (this.fullData.resulGeneral.results != "") {
						this.fullData.resulGeneral.resulComplete = res.data;
						let i = Object.values(res.data)[Object.values(this.fullData.resulGeneral)[0]];
						this.fullData.resulGeneral.resulTable = i;
					} else {
						this.fullData.resulGeneral.resulTable = res.data;
					}
				} catch (error) {
					console.log(error);
				}
				this.loading = false
			},
			/* Fin consulta de listados */

			/* Paginador */
			async navPage(page) {
				if (page != 0) {
					this.loading = true
					try {
						const res = await axios.get(`${this.fullData.url}&page=${page}`, {
							method: 'GET',
							headers: {
								'Content-Type': 'application/json',
								'Authorization': 'Bearer ' + localStorage.getItem('token')
							}
						});
						if (this.fullData.resulGeneral.results != "") {
							this.fullData.resulGeneral.resulComplete = res.data;
							let i = Object.values(res.data)[Object.values(this.fullData.resulGeneral)[0]];
							this.fullData.resulGeneral.resulTable = i;
						} else {
							this.fullData.resulGeneral.resulTable = res.data;
						}
						this.pag = page
					} catch (error) {
						console.log(error.data);
					}
					this.loading = false
				}
			},
			/* Fin Paginador */

			/* Combertir texto en minusculas */
			minusculas(str) {
				if (str != undefined) {
					if (typeof str != "number") {
						return str.toLowerCase();
					} else {
						return str;
					}
				} else {
					return str = "";
				}
			},
			/* Fin Combertir texto en minusculas */

			esNumero() {
				if(!isNaN(parseInt(this.keyBusqueda))){
					this.keyBusqueda = parseInt(this.keyBusqueda)
				}
			},

			tipoValroFiltrado() {
				for (let i = 0; i < this.fullData.filtrar.length; i++) {
					if (this.fullData.filtro == Object.keys(this.fullData.filtrar[i])[0]) {
						this.tipoVariable = Object.values(this.fullData.filtrar[i])[1];
					}
				}
			},

			/* Ejecutar modal */
			modalProduc(id) {
				if (id != false || id === 0) {
					this.fullData.modal.dataModal = this.fullData.resulGeneral.resulTable[id];
					this.fullData.modal.tipo = 1;
				}
				this.fullData.modal.estadoModal = true
				document.getElementsByTagName('body')[0].style.overflow = 'hidden'
			},
			/* Fin Ejecutar modal */

		},
		mounted() {
			this.userData = JSON.parse(localStorage.getItem("userData"));
			if (this.userData.user != "superuser") {
                this.selected = this.userData.costumer;
            }
			this.fullData = this.fullDataParam;
			this.conProductos()
		},
		watch: {
			/* selected() {
				this.conProductos(),
				this.producModal.customer = this.selected
			} */
		},
		computed: {
			/* tipoVariable: function () {
				this.esNumero();
				if (typeof (this.keyBusqueda) == "string") {
					return "text"
				} else {
					return "number"
				}
			}, */
			combertirArrye: function () {
				let a = Math.ceil(this.fullData.resulGeneral.resulComplete.count / this.NUM_RESULTS);
				let i = [a];
				for (let x = 0; x < a; x++) {
					i[x] = x + 1;
				}
				return i;
			}
		}
	}
</script>

<style scoped>
</style>