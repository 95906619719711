<template>
    <div>

        <!-- Vista de Movimientos -->        
        <div class="oculto-impresion" v-if="nuevaOrden == false">
            <p class="font-weight-bold position-absolute d-flex align-items-center" style="margin-top: -40px">
                <i  @click="cerrar" class='bx bx-chevron-left btnAtras'></i>
            </p>
            <h1 class="text-primary text-uppercase font-weight-bold">{{ detalleProveedor.name }}</h1>

            <div class="my-4 py-4">
                <button class="btn btn-success font-weight-bold mr-4" @click="addNewOrder(0)">Nueva Entrada</button>
                <button class="btn btn-danger font-weight-bold" @click="addNewOrder(1)">Nueva Salida</button>
            </div>

            <div>
                <div class="container">
                    <div class="row justify-content-center">
                        <div style="width: 100%;">
                            <div class="card">
                                <div class="card-header">
                                    <h4>Movimientos Anteriores</h4>
                                </div>
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th class="font-weight-bold">Fecha</th>
                                                    <th class="font-weight-bold">Tipo</th>
                                                    <th class="font-weight-bold nomovil">Descripción</th>
                                                    <th class="font-weight-bold">Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(entrada, i) in entradas.results" :key="i">
                                                    <td>{{ entrada.created_at.split("T")[0] }}</td>
                                                    <td :class="{'text-danger': entrada.movement_type == 'exit', 'text-success': entrada.movement_type == 'entry'}">
                                                        <span v-if="entrada.movement_type == 'exit'">Salida</span>
                                                        <span v-else>Entrada</span>
                                                    </td>
                                                    <td class="nomovil">{{ entrada.description }}</td>
                                                    <td>
                                                        <button class="buttonInfo" @click="verDetalleMovimiento(i)">
                                                            <i class='bx bx-search-alt' ></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Fin Vista de movimientos -->


        <!-- Vista de impresion -->
        <div style="background: rgba(0, 0, 0, .5); z-index: 100;" v-if="vistaImpresion" class="position-fixed oculto-impresion w-100 h-100 fixed-top"></div>

        <div v-if="vistaImpresion" style="z-index: 200;" class="d-flex align-items-auto justify-content-center py-5 position-fixed w-100 h-100 fixed-top px-2">

            <div style="background: rgba(255, 255, 255, 1); max-width: 600px;" class="py-5 px-4 overflow-auto rounded">
                <p class="font-weight-bold position-absolute d-flex align-items-center oculto-impresion" style="margin-top: -20px; margin-left: -15p<;">
                    <i  @click="vistaImpresion = false" class='bx bx-x text-primary btnAtras'></i>
                    <button class="btn btn-primary" @click="imprimir"><i class='bx bx-printer' ></i></button>
                </p>

                <div class="mx-auto">
                    <img src="/logoHeader.png" alt="">
                    <p><b>NIT: </b>12345678</p>
                    <h4>Comprobante de movimiento</h4>
                    <hr>
                    <table class="my-4">
                        <thead>
                            <tr>
                                <th>Fecha</th>
                                <th>Tipo</th>
                                <th>Items</th>
                                <!-- <th>Estado</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ detalleMovimiento.created_at.split("T")[0] }}</td>
                                <td :class="{'text-danger': detalleMovimiento.product_details[0].description.toLowerCase() == 'salida'.toLowerCase(), 'text-success': detalleMovimiento.product_details[0].description.toLowerCase() == 'entrada'.toLowerCase()}"><span class="text-capitalize">{{ detalleMovimiento.product_details[0].description }}</span></td>
                                <td>{{ detalleMovimiento.product_details.length }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <hr>
                    <div class="my-4">
                        <div class="d-inline-block w-50 text-left">
                            <b>Cliente:</b> <span class="text-uppercase">{{ detalleProveedor.name }}</span>
                        </div>
                        <div class="d-inline-block w-50 text-left">
                            <b>ID:</b> {{ detalleMovimiento.id }}
                        </div>
                        <div class="d-inline-block w-50 text-left">
                            <b>Bodega:</b> <span class="text-capitalize">No viene</span>
                        </div>
                        <div class="d-inline-block w-50 text-left">
                            <b></b> <span class="text-capitalize"></span>
                        </div>
                    </div>
                    <hr>
                    <div class="my-4">

                        <table>
                            <thead>
                                <tr>
                                    <th>Lote</th>
                                    <th>Producto</th>
                                    <th>Cantidad</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(producto, e) in detalleMovimiento.product_details" :key="e">
                                    <td class="pl-2 pt-2">{{ producto.lot }}</td>
                                    <td class="pl-2 pt-2">{{ producto.product_name }}</td>
                                    <td class="pl-2 pt-2">{{ producto.product_qty }}</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                    <hr>

                </div>
            </div>
        </div>
        <!-- Fin Vista de impresion -->


        <!-- Creación de ordenes -->
        <modal-nueva-orden v-if="nuevaOrden" :newOrderData="newOrderData" @cerrar="cerrarOrden" />
        <!-- Fin Creación de ordenes -->


    </div>
</template>

<script>
    import modalNuevaOrden from './modalNuevaOrden.vue';
    import axios from 'axios';
    export default {
        name: 'listadoOrdenes',
        components: {
            modalNuevaOrden,
        },
        props: [
            'detalleProveedor'
        ],
        data() {
            return {
                vistaImpresion: false,
                nuevaOrden: false,
                url: 'http://apiinventory.eba-zdkhf2sk.us-west-2.elasticbeanstalk.com/v1',
                newOrderData: {
                    title: 'Orden',
                    nameCustomer: '',
                    idCustomer: '',
                },
                entradas: [/* {
                    date: '12/12/2020',
                    tipe: 'Entrada',
                    quantity: '10',
                    state: 'Completo'
                }, {
                    date: '12/12/2020',
                    tipe: 'Salida',
                    quantity: '10',
                    state: 'Completo'
                }, {
                    date: '12/12/2020',
                    tipe: 'Salida',
                    quantity: '10',
                    state: 'Completo'
                }, {
                    date: '12/12/2020',
                    tipe: 'Entrada',
                    quantity: '10',
                    state: 'En Proceso'
                }, {
                    date: '12/12/2020',
                    tipe: 'Entrada',
                    quantity: '10',
                    state: 'Completo'
                }, {
                    date: '12/12/2020',
                    tipe: 'Entrada',
                    quantity: '10',
                    state: 'Completo'
                }, {
                    date: '12/12/2020',
                    tipe: 'Entrada',
                    quantity: '10',
                    state: 'Completo'
                }, {
                    date: '12/12/2020',
                    tipe: 'Entrada',
                    quantity: '10',
                    state: 'Completo'
                }, {
                    date: '12/12/2020',
                    tipe: 'Entrada',
                    quantity: '10',
                    state: 'Completo'
                }, {
                    date: '12/12/2020',
                    tipe: 'Entrada',
                    quantity: '10',
                    state: 'Completo'
                }, {
                    date: '12/12/2020',
                    tipe: 'Entrada',
                    quantity: '10',
                    state: 'Completo'
                }, {
                    date: '12/12/2020',
                    tipe: 'Entrada',
                    quantity: '10',
                    state: 'Completo'
                } */],
                detalleMovimiento: "",
            }
        },
        methods: {
            cerrar() {
                this.$emit('cerrar');
            },

            async listarEntradas() {
                try {
                    const res = await axios.get(`${this.url}/customers/${this.detalleProveedor.id}/movements`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                    });
                    this.entradas = res.data;
                } catch (error) {
                    console.log(error);
                }
            },

            imprimir() {
                window.print()
            },
            
            verDetalleMovimiento(i) {
                this.vistaImpresion = true;
                this.detalleMovimiento = this.entradas.results[i];
            },

            addNewOrder(i) {
                if (i == 0) {
                    this.newOrderData.title = 'Orden';
                } else {
                    this.newOrderData.title = 'Salida';
                }
                this.newOrderData.nameCustomer = this.detalleProveedor.name;
                this.newOrderData.idCustomer = this.detalleProveedor.id;
                this.nuevaOrden = true;
            },

            cerrarOrden() {
                this.nuevaOrden = false;
                this.newOrderData = {};
            }
        },
        computed: {
        },
        mounted() {
            this.listarEntradas();
        },
    }
</script>

<style scoped>
    td {
        text-align: center;
    }
    .buttonInfo {
        background: transparent;
        border: 0; font-size: 1.5em;
        transition: transform 0.2s;
    }
    .buttonInfo:hover {
        transform: scale(1.15);
		transition: transform 0.2s;
    }
    hr {
        border-top: 1px solid rgb(0 0 0 / 48%);
    }
</style>