<template>
    <div class="container text-center mb-5 mt-5 py-5"  id="conten">

        <!-- Cargando... -->
        <div v-if="loading" class="mt-5">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>


        <div class="container mb-5">
            <div class="iFrame" v-if="userData.user == 'staff' || userData.user == 'superuser'">
                <iframe v-show="iframe" @load="frameLoaded" src="https://data.todoscomemos.com/public/dashboard/b6a01980-6fca-421e-9dbd-fcea62d0b82a" frameborder="0" scrolling="yes"></iframe>
            </div>
            <div class="iFrame" v-else>
                <iframe v-show="iframe" @load="frameLoaded" src="https://data.todoscomemos.com/public/dashboard/b6a01980-6fca-421e-9dbd-fcea62d0b82a" frameborder="0" scrolling="yes"></iframe>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "homeView",
        data() {
            return {
                /* Data user */
                userData:"",
                /* Fin data user */

                loading: true,
                iframe: false,

                selected: 1,
                producs: [],
            }
        },
        components: {
        },
        methods: {
            nuevaLista(id) {
                this.producs = id;
            },
            frameLoaded() {
                this.loading = false;
                this.iframe = true;
            },
        },
        mounted() {
            this.userData = JSON.parse(localStorage.getItem("userData"));
        }
    }
</script>

<style>
    .iFrame {
        position: relative;
        padding-bottom: 150%;
        height: 0;
        overflow: hidden;
    }

    .iFrame iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
</style>
