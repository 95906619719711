<template>
    <div>
        <div class="container text-center mb-5 mt-5 py-5" id="conten">
            <h1 class="mb-3">Gestión de Productos</h1>

            <!-- Selector de bodega (Solo Superadmin) -->
            <div class="selector-bodega position-absolute" v-if="userData.user == 'superuser'">
                <select class="form-control" v-model="userData.bodega" @change="cambiarBodega">
                    <option value="0" disabled>Bodega</option>
                    <optgroup v-for="(city, i) in allbodegas" :key="i" :label="city.name">
                        <option v-for="(bodega, o) in city.warehouses" :key="o" :value="bodega.id"> {{ bodega.name }} </option>
                    </optgroup>
                </select>
            </div>

            <!-- Lista de proveedores conProductos-->
            <div v-if="this.userData.user == 'superuser' || this.userData.user == 'staff'" class="form-group mt-4">
                <select class="form-control" v-model="selected">
                    <option value="0">Selecciona el proveedor</option>
                    <option v-for="prove in provedores.results" :key="prove.id" :value="prove.id">
                        {{ prove.name }}
                    </option>
                </select>
                <!-- {{ userData.bodega }} -->
            </div>

            <!-- Listado de productos -->
            <listado-productos v-if="cambioBodega == false" :producs="producs" :selected="selected" @actualizaLista="nuevaLista" />

            <div v-if="loading">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    /* import { decode } from "punycode"; */
    import listadoProductos from '@/components/productosComponents/listadoProductos.vue';
    const URL = "http://apiinventory.eba-zdkhf2sk.us-west-2.elasticbeanstalk.com/v1";
    import axios from "axios";
    export default {
        name: 'productosView',
        components: {
            listadoProductos
        },
        data() {
            return {
                /* Data user */
                userData: {},
                allbodegas: "",
                bodegaUnica: 0,
                /* Fin data user */

                provedores: [],
                selected: 0,
                producs: [],
                url: '',
                cambioBodega: false,
                loading: false,
            }
        },
        methods: {
            /* consultar lista de proveedores*/
            async conProvedores() {
                try {
                    const res = await axios.get(URL + `/customers`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                    });
                    this.provedores = res.data;
                } catch (error) {
                    console.log(error);
                }
            },

            nuevaLista(i) {
                this.producs = i;
            },

            /* Consultar lista de Bodegas */
            async listarBodegas() {
                if (this.userData.user == 'superuser') {
                    let res = await axios.get(`${URL}/cities`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                    });
                    this.allbodegas = res.data;
                }
            },

            async cambiarBodega() {
                this.cambioBodega = true;
                this.loading = true;
                const hederjson = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
                const bodyjson = {
                    "warehouse": this.userData.bodega
                }
                try {
                    await axios.patch(`${URL}/warehouses_admins/${this.userData.id}`, bodyjson, {
                        headers: hederjson
                    });
                    
                    localStorage.setItem("userData", JSON.stringify({
                        user: "superuser",
                        bodega: this.userData.bodega,
                        id: this.userData.id
                    }));
                } catch (error) {
                    console.log(error);
                }
                this.loading = false;
                this.cambioBodega = false;
            }
        },
        mounted() {
            this.userData = JSON.parse(localStorage.getItem("userData"));
            if (this.userData.customer == null) {
                this.selected = 0;
            }else{
                this.selected = this.userData.customer;
            }

            if (this.userData.user == "superuser") {
                this.listarBodegas()
            }

            if (this.userData.user != 'standard') {
                this.conProvedores()
            }
        }
    }
</script>

<style scoped>
    .selector-bodega {
        top: 4rem;
        right: initial;
        /* left: 1rem; */
    }

    @media screen and (min-width: 768px) {
        .selector-bodega {
            top: 5rem;
            right: 1rem;
        }
    }
</style>