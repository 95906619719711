<template>
    <!-- Ventana modal para visualizar producto -->
    <transition>
        <div class="modal" tabindex="-1" role="dialog" v-if="modal">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header mb-4">
                        <h5 class="modal-title" v-if="Producto.pey == false">Nuevo Producto</h5>
                        <h5 class="modal-title" v-else maxlength="10">{{ Producto.name.substr(0, 16) }}
                            <span v-if="Producto.name.length > 16">...</span>
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                            @click="cerrarModal">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>


                    <div class="menu-info-editar" v-if="Producto.pey != false">
                        <button v-if="producModal.pey != false" :class="{buttoninfoeditar: infoeditar == true}" @click="infoeditar = true">Información</button>
                        <button :class="{buttoninfoeditar: infoeditar == 'stock'}" @click="infoeditar = 'stock'">Stock</button>
                        <button v-if="userData.user == 'staff' || userData.user == 'superuser'" :class="{buttoninfoeditar: infoeditar == false}" @click="infoeditar = false">Editar</button>
                    </div>


                    <div class="modal-body" v-if="infoeditar == true && producModal.pey != false">
                        <!-- mostrar editar todo el producto -->
                        <div class="panel-info">
                            <span>SKU:</span>
                            <p>{{ Producto.sku }}</p>
                        </div>
                        <div class="panel-info">
                            <span>Nombre:</span>
                            <p>{{ Producto.name}}</p>
                        </div>
                        <div class="panel-info">
                            <span>Codigo de Barras:</span>
                            <p>
                                <barcode v-bind:value="Producto.ean" height="55" >Codigo no valido.</barcode>
                            </p>
                        </div>
                        <div v-if="Producto.perishable" class="panel-info">
                            <span>Perecedero:</span>
                            <p>Si</p>
                        </div>
                        <div v-else class="panel-info">
                            <span>Perecedero:</span>
                            <p>no</p>
                        </div>
                        <div class="panel-info">
                            <span>Descripción:</span>
                            <p>{{ Producto.description }}</p>
                        </div>
                    </div>

                    <!-- mostrar stock todo el producto -->
                    <div class="modal-body"  v-else-if="infoeditar == 'stock'">
                        <div v-for="(a,i) in Producto.available" :key="i">
                            <div class="panel-info">
                                <span>Bodega:</span>
                                <p>{{ a.warehouse_name }}</p>
                            </div>
                            <div class="panel-info">
                                <span>Lote:</span>
                                <p>{{ a.lot_id }}</p>
                            </div>
                            <div class="panel-info" v-if="Producto.perishable">
                                <span>Vencimiento:</span>
                                <p>{{ fecha(a.due_date) }}</p>
                            </div>
                            <div class="panel-info">
                                <span>Stock:</span>
                                <p>{{ a.stock }}</p>
                            </div>
                            <hr>
                        </div>
                    </div>

                    <!-- mostrar editar todo el producto -->
                    <div class="modal-body"  v-else-if="infoeditar != 'stock' && userData.user == 'staff' || userData.user == 'superuser'" >
                        <div class="form-group">
                            <label for="sku">SKU</label>
                            <input type="number" class="form-control" id="sku" v-model="Producto.sku" required>
                        </div>
                        <div class="form-group">
                            <label for="name">Nombre</label>
                            <input type="text" class="form-control" id="name" v-model="Producto.name" required>
                        </div>
                        <div class="form-group">
                            <label for="description">Descripción</label>
                            <textarea class="form-control" id="description" v-model="Producto.description"></textarea>
                        </div>
                        <div class="form-group">
                            <label for="ean">EAN</label>
                            <input type="number" class="form-control" id="ean" v-model="Producto.ean" required>
                        </div>
                        <div class="form-group">
                            <label for="perishable">Perecedero</label>
                            <select class="form-control" id="perishable" v-model="Producto.perishable" required>
                                <option v-if="Producto.pey === false" :selected="Producto.perishable == ''" value="" disabled>Seleccionar</option>
                                <option v-else disabled>Seleccionar</option>

                                <option v-if="Producto.perishable == true" value="true">Si</option>
                                <option v-else-if="Producto.perishable == false" value="true">Si</option>
                                <option v-else value="true">Si</option>

                                <option v-if="Producto.perishable == true" value="false">No</option>
                                <option v-else-if="Producto.perishable == false" value="false">No
                                </option>
                                <option v-else value="false">No</option>
                            </select>
                        </div>
                    </div>


                    <!-- botones de la ventana modal -->
                    <div class="modal-footer mt-4">
                        <button type="button" class="btn btn-secondary" @click="cerrarModal">Cancelar</button>
                        <button v-if="Producto.pey == false" type="button" class="btn btn-primary"
                            @click="confirmarbtn('guardar')">Guardar</button>
                        <div v-else-if="infoeditar == false">
                            <button type="button" class="btn btn-primary"
                                @click="confirmarbtn('actualizar')">Actualizar</button>
                            <button type="button" class="btn btn-danger ml-2" data-dismiss="modal"
                                @click="confirmarbtn('eliminar')">Eliminar</button>
                        </div>
                    </div>

                    <!-- Botons confirmar -->
                    <div v-if="btnconfirmar != ''" class="btnconfirmar">
                        <div>
                            <p>¿Seguro que desea {{ btnconfirmar }}?</p>
                            <button type="button" class="btn btn-secondary" @click="cerrarConfirmar">No</button>
                            <button v-if="btnconfirmar == 'guardar'" type="button" class="btn btn-primary ml-2" @click="crearProducto">Si</button>
                            <button v-if="btnconfirmar == 'actualizar'" type="button" class="btn btn-primary ml-2" @click="actualizarProducto">Si</button>
                            <button v-if="btnconfirmar == 'eliminar'" type="button" class="btn btn-danger ml-2" data-dismiss="modal" @click="eliminarProducto">Si</button>
                        </div>
                    </div>

                    <!-- Notificacion toast -->
                    <div v-if="msgtoast != ''" class="align-items-center text-white border-0 position-fixed" :class="{'bg-success': msgtoast != 'error', 'bg-danger': msgtoast == 'error'}" style="right:35px; bottom:35px" role="alert" aria-live="assertive" aria-atomic="true">
                        <div class="d-flex">
                            <div v-if="msgtoast == 'completado'" class="toast-body">
                                Proceso realizado con exito.
                            </div>
                            <div v-if="msgtoast == 'error'" class="toast-body">
                                Error en el proceso.
                            </div>
                            <button type="button" class="btn-close border-0 text-white mr-3" :class="{'bg-success': msgtoast != 'error', 'bg-danger': msgtoast == 'error'}" data-bs-dismiss="toast" aria-label="Close" @click="msgtoast = ''">x</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import axios from "axios";
    import VueBarcode from 'vue-barcode';
    export default {
        components: {
            'barcode': VueBarcode
        },
        data() {
            return {
                userData: {},
                btnconfirmar: '',
                msgtoast: '',
                urlg: "http://apiinventory.eba-zdkhf2sk.us-west-2.elasticbeanstalk.com",
                infoeditar: true,
                Producto: {
                    sku: '',
                    name: '',
                    description: '',
                    ean: '',
                    perishable: '',
                    pey: false
                },
               /*  perishable: "" */
            }
        },
        props: {
            producModal: undefined,
            modal: undefined,
        },
        methods: {
            cerrarModal() {
                this.btnconfirmar = '';
                this.$emit('cerrar', false)
                document.getElementsByTagName('body')[0].style.overflow = 'auto'
                this.infoeditar = true
            },

            cerrarConfirmar () {
                this.btnconfirmar = '';
            },

            /* Crear producto */
            async crearProducto() {
                this.loading = true;
                this.btnconfirmar = '';
                const hederjson = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
                if (this.producModal.description == ""){
                    var description = null
                } else {
                    description = this.producModal.description
                }
                const bodyjson = {
                    "sku": parseInt(this.producModal.sku),
                    "ean": parseInt(this.producModal.ean),
                    "name": this.producModal.name,
                    "description": description,
                    "perishable": this.Producto.perishable
                }
                console.log(bodyjson)
                try {
                    const res = await axios.post(`${this.urlg}/v1/customers/${this.producModal.customer}/products`, bodyjson, {
                        headers: hederjson
                    });
                    this.msgtoast = 'completado'
                    setTimeout(() => {
                        this.msgtoast = res.data
                        this.msgtoast = ''
                        this.cerrarModal()
                    }, 5000);
                } catch (error) {
                    this.msgtoast = 'error'
                    setTimeout(() => {
                        this.msgtoast = error;
                        this.msgtoast = ''
                    }, 5000);
                }
                this.loading = false
            },
            /* actualizar Producto */
            async actualizarProducto() {
                this.loading = true;
                this.btnconfirmar = '';

                if (this.Producto.perishable == "") {
                    this.Producto.perishable = false
                }

                const hederjson = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
                const bodyjson = {
                    "sku": parseInt(this.producModal.sku),
                    "name": this.producModal.name,
                    "description": this.producModal.description,
                    "ean": parseInt(this.producModal.ean),
                    "perishable": this.Producto.perishable,
                    "customer": parseInt(this.producModal.customer)
                }
                try {
                    const res = await axios.put(`${this.urlg}/v1/customers/${this.producModal.customer}/products/${this.producModal.id}`, bodyjson, {
                        headers: hederjson
                    });
                    this.msgtoast = 'completado'
                    setTimeout(() => {
                        this.msgtoast = res.data
                        this.msgtoast = ''
                        this.cerrarModal()
                    }, 5000);
                } catch (error) {
                    this.msgtoast = 'error'
                    setTimeout(() => {
                        this.msgtoast = error;
                        this.msgtoast = ''
                    }, 5000);
                }
                this.loading = false
            },
            /* Eliminar producto */
            async eliminarProducto() {
                this.btnconfirmar = '';
                const hederjson = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
                const bodyjson = {
                    "is_deleted": true,
                }
                try {
                    const res = await axios.patch(`${this.urlg}/v1/customers/${this.producModal.customer}/products/${this.producModal.id}`, bodyjson, {
                        headers: hederjson
                    });
                    this.msgtoast = 'completado'
                    setTimeout(() => {
                        this.msgtoast = res.data
                        this.msgtoast = ''
                        this.cerrarModal()
                    }, 5000);
                } catch (error) {
                    this.msgtoast = 'error'
                    setTimeout(() => {
                        this.msgtoast = error;
                        this.msgtoast = ''
                    }, 5000);
                }
                this.loading = false
            },
            confirmarbtn(id) {
                this.btnconfirmar = id
            },
            fecha(id) {
                return id.slice(8, 10) + '/' + id.slice(5, 7) + '/' + id.slice(0, 4)
            }
        },
        watch: {
            modal() {
                this.Producto = this.producModal
            }
        },
        mounted() {
            this.userData = JSON.parse(localStorage.getItem("userData"));
            /* if (this.producModal.pey == false) {
                this.infoeditar = false
            } */
        },
    }
</script>

<style scoped>
    .menu-info-editar{
        margin: 0 15px;
        /* padding-top: 1rem; */
        text-align: left;
        z-index: 10;
    }
    .menu-info-editar button {
        background-color: white;
        border: solid 2px #cbcbcb;
        border-radius: 5px 5px 0 0;
        margin-bottom: -2px;
        padding: 10px;
        padding-bottom: 3px;
    }
    .menu-info-editar button:hover {
        font-style: italic;
    }
    .modal-body {
        border: solid 2px #cbcbcb;
        margin: 0 15px;
        text-align: left;
    }
    .menu-info-editar .buttoninfoeditar {
        border-bottom: solid 2px white;
    }
    .panel-info {
        padding: 10px;
    }
    .panel-info, .panel-info span, .panel-info p {
        display: inline-block;
        vertical-align: middle;
    }
    .panel-info span {
        font-weight: bold;
    }
    .panel-info p {
        border-bottom: solid 1px #cbcbcb;
        margin: 0;
        margin-left: 10px;
    }
    .tablaModal td {
        text-align: center;
    }
    .btnconfirmar {
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        width: 100vw;
        height: 100vh;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
    }
    .btnconfirmar div {
        background-color: white;
        border-radius: 10px;
        padding: 25px;
    }
</style>