<template>
    <div class="container text-center mb-5 mt-5 py-5"  id="conten">
        <listar-variado :fullDataParam="fullDataParam" />

		<div v-if="fullDataParam.modal.estadoModal" class="modal" tabindex="-1">
            <div class="modal-dialog overflow-auto">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ Object.values(fullDataParam.modal.tiposModal)[fullDataParam.modal.tipo] }}</h5>
                        <button type="button" class="btn-close border-0" data-bs-dismiss="modal" aria-label="Close" @click="cerrarModal()"><i class='bx bx-x'></i></button>
                    </div>

                    <!-- Crear Usuario -->
                    <div v-if="fullDataParam.modal.tipo == 0" class="modal-body">
                        <div class="form-group text-left">
                            <label for="name">Nombre del cliente</label>
                            <input type="text" class="form-control" id="name" v-model="fullDataParam.modal.dataModal.name">
                        </div>
                        <div class="form-group text-left">
                            <label for="email">Email</label>
                            <input type="email" class="form-control" id="email" v-model="fullDataParam.modal.dataModal.email">
                        </div>
                        <div class="form-group text-left">
                            <label for="telefono">Teléfono</label>
                            <input type="text" class="form-control" id="password" v-model="fullDataParam.modal.dataModal.phone_number">
                        </div>
                        <div class="form-group text-left">
                            <label for="industria">Industria</label>
                            <input type="text" class="form-control" id="industria" v-model="fullDataParam.modal.dataModal.industry">
                        </div>
                        <div class="form-group text-left">
                            <label for="prefijo">Prefijo</label>
                            <input type="text" class="form-control" id="prefijo" v-model="fullDataParam.modal.dataModal.slug_name">
                        </div>

                        <div class="form-group text-left">
                            <label>Usuario asignado</label>
                            <select class="form-control" v-model="fullDataParam.modal.dataModal.user_id">
                                <option value="" disabled>Seleccionar</option>
                                    <option v-for="(user, o) in usuarios.results" :key="o" :value="user.id">
                                    {{ user.username }}</option>
                            </select>
                        </div>
                    </div>

                    <!-- Editar Usuario -->
                    <div v-else-if="fullDataParam.modal.tipo == 1" class="modal-body">
                        <div class="form-group text-left">
                            <label for="name">Nombre del cliente</label>
                            <input type="text" class="form-control" id="name" v-model="fullDataParam.modal.dataModal.name">
                        </div>
                        <div class="form-group text-left">
                            <label for="email">Email</label>
                            <input type="email" class="form-control" id="email" v-model="fullDataParam.modal.dataModal.email">
                        </div>
                        <div class="form-group text-left">
                            <label for="telefono">Teléfono</label>
                            <input type="text" class="form-control" id="password" v-model="fullDataParam.modal.dataModal.phone_number">
                        </div>
                        <div class="form-group text-left">
                            <label for="industria">Industria</label>
                            <input type="text" class="form-control" id="industria" v-model="fullDataParam.modal.dataModal.industry">
                        </div>
                        <div class="form-group text-left">
                            <label for="prefijo">Prefijo</label>
                            <input type="text" class="form-control" id="prefijo" v-model="fullDataParam.modal.dataModal.slug_name">
                        </div>

                        <div class="form-group text-left">
                            <label>Usuario asignado</label>
                            <select class="form-control" v-model="fullDataParam.modal.dataModal.user">
                                <option value="undefined" disabled>Seleccionar</option>
                                    <option v-for="(user, o) in usuarios.results" :key="o" :value="user.id">
                                    {{ user.username }}</option>
                            </select>
                        </div>
                    </div>


                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="cerrarModal()">Cerrar</button>
                        <button v-if="fullDataParam.modal.tipo == 0" type="button" class="btn btn-primary" @click="confirmarbtn('crear')">Crear</button>
                        <button v-if="fullDataParam.modal.tipo == 1" type="button" class="btn btn-primary" @click="confirmarbtn('guardar')">Guardar</button>
                    </div>
                </div>
            </div>


            <!-- Botons confirmar -->
            <div v-if="btnconfirmar != ''" class="btnconfirmar">
                <div>
                    <p>¿Seguro que desea {{ btnconfirmar }}?</p>
                    <button type="button" class="btn btn-secondary" @click="cerrarConfirmar">No</button>
                    <button v-if="btnconfirmar == 'guardar'" type="button" class="btn btn-primary ml-2" @click="guardar()">Si</button>
                    <button v-if="btnconfirmar == 'crear'" type="button" class="btn btn-primary ml-2" @click="crearCliente()">Si</button>
                </div>
            </div>

            <!-- Notificacion toast -->
            <div v-if="msgtoast != ''" class="align-items-center text-white border-0 position-fixed" :class="{'bg-success': msgtoast != 'error', 'bg-danger': msgtoast == 'error'}" style="right:35px; bottom:35px" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="d-flex">
                    <div v-if="msgtoast == 'completado'" class="toast-body">
                        Proceso realizado con exito.
                    </div>
                    <div v-if="msgtoast == 'error'" class="toast-body">
                        Error en el proceso.
                    </div>
                    <button type="button" class="btn-close border-0 text-white mr-3" :class="{'bg-success': msgtoast != 'error', 'bg-danger': msgtoast == 'error'}" data-bs-dismiss="toast" aria-label="Close" @click="msgtoast = ''">x</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	import listarVariado from '@/components/listarVariado.vue';
	import axios from 'axios';
	const url = 'http://apiinventory.eba-zdkhf2sk.us-west-2.elasticbeanstalk.com/v1';
    export default {
        name: "clientesView",
        data() {
            return {
                userData: {},
				msgtoast: '',
				msg: '',
                btnconfirmar: '',
				provedores: {},
				usuarios: {results: {}},
                tipoUser: '',
                fullDataParam: {
					url: `${url}/customers`,
					botonCrear: "Crear Cliente",/* El texto del boton superior */
					filtro: 0,/* El parametro de la busqueda, 0 para que aparesca filtrar*/
					filtrar: [ /* Data para filtrar la busqueda */
                        {name: "Nombre", type : "text"},
						{ID: 'ID', type : "number"},
					],
					resulGeneral: {
						results: "3", /* pocicion de los datos a recorrer en caso de no requerir enviar "" */
						resulComplete: [],/* Si el resultado trae mas datos en la consulta mas la tabla */
						resulTable: [] /* Lo que se va a listar en la tabla */
					},
					tableParams: [
						{Name: "", titleName: "Cliente"},
						{Email: "nomovil", titleName: "Email"},
						{Phone_number: "notable", titleName: "Télefono"},
						{Industry: "notable", titleName: "Tipo de producto"},
					],
					modal: {/* Todos los datos de la modal */
						estadoModal: false,
						tiposModal: {
							0: "Crear Cliente",
							1: "Editar Cliente"
						}, /* 0 = Crear Usuario, 1 = Editar Usuario*/
						tipo: 0,
						dataModal: {
							/* data agregada manualmente (lo traera desde el componente) */
							name: "",
							email: "",
							phone_number: "",
							industry: "",
							slug_name: "",
							user_id: "",
							created_at: "",
						},
					},
				}
            }
        },
        components: {
            listarVariado
        },
		methods: {
			cerrarModal() {
				this.fullDataParam.modal.estadoModal = false;
				this.fullDataParam.modal.dataModal = {
							name: "",
							email: "",
							phone_number: "",
							industry: "",
							slug_name: "",
							user_id: "",
							created_at: "",
						};
				document.getElementsByTagName('body')[0].style.overflow = 'auto'
			},

			async conUsuarios() {
                try {
                    const res = await axios.get(`${url}/users?is_staff=false`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                    });

                    if (this.usuarios.results != undefined) {
                        this.usuarios.results = [];
                    }
                    for (let i = 0; i < res.data.length; i++) {
                        if (res.data[i].customer_id != 1000) {
                            this.usuarios.results.push(res.data[i])
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
            },

            async crearCliente() {
                if (this.fullDataParam.modal.dataModal.name != '' && this.fullDataParam.modal.dataModal.email != '' && this.fullDataParam.modal.dataModal.phone_number != '' && this.fullDataParam.modal.dataModal.industry != '' && this.fullDataParam.modal.dataModal.slug_name != '') {
                    this.loading = true
                    this.fullDataParam.modal.dataModal.name = this.fullDataParam.modal.dataModal.name.toLowerCase()
                    this.fullDataParam.modal.dataModal.name = this.fullDataParam.modal.dataModal.name[0].toUpperCase() + this.fullDataParam.modal.dataModal.name.slice(1)
                    this.fullDataParam.modal.dataModal.slug_name = this.fullDataParam.modal.dataModal.slug_name.toUpperCase()
                    if (this.fullDataParam.modal.dataModal.user != "") {
                        this.fullDataParam.modal.dataModal.user = null
                    }
                    let fullData = {
                        name: this.fullDataParam.modal.dataModal.name,
                        email: this.fullDataParam.modal.dataModal.email,
                        phone_number: this.fullDataParam.modal.dataModal.phone_number,
                        industry: this.fullDataParam.modal.dataModal.industry,
                        slug_name: this.fullDataParam.modal.dataModal.slug_name,
                    }
                    try {
                        const res = await axios.post(`${this.fullDataParam.url}`, fullData, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + localStorage.getItem('token')
                            }
                        });
                        this.msg = "Cliente creado correctamente"
                        this.msgtoast = "completado"
                        setTimeout(() => {
                            this.msgtoast = res.data.message;
                            this.msgtoast = ''
                            this.cerrarModal()
                        }, 3000);
                    } catch (error) {
                        this.msg = "Error al crear el Cliente"
                        this.msgtoast = "error"
                        setTimeout(() => {
                            this.msgtoast = error;
                            this.msgtoast = ''
                            this.cerrarModal()
                        }, 3000);
                    }
                    this.loading = false
                }else {
                    this.msg = "Todos los campos son obligatorios"
                    this.msgtoast = "error"
                    setTimeout(() => {
                        this.msgtoast = ''
                    }, 5000);
                }
                this.cerrarConfirmar ()
            },

            async guardar() {
                if (this.fullDataParam.modal.dataModal.name != '' && this.fullDataParam.modal.dataModal.email != '' && this.fullDataParam.modal.dataModal.phone_number != '' && this.fullDataParam.modal.dataModal.industry != '' && this.fullDataParam.modal.dataModal.slug_name != '') {
                    this.loading = true
                    this.fullDataParam.modal.dataModal.name = this.fullDataParam.modal.dataModal.name.toLowerCase()
                    this.fullDataParam.modal.dataModal.name = this.fullDataParam.modal.dataModal.name[0].toUpperCase() + this.fullDataParam.modal.dataModal.name.slice(1)
                    this.fullDataParam.modal.dataModal.slug_name = this.fullDataParam.modal.dataModal.slug_name.toUpperCase()
                    /* if (this.fullDataParam.modal.dataModal.user != "") {
                        this.fullDataParam.modal.dataModal.user = null
                    } */
                    let fullData = {
                        name: this.fullDataParam.modal.dataModal.name,
                        email: this.fullDataParam.modal.dataModal.email,
                        phone_number: this.fullDataParam.modal.dataModal.phone_number,
                        industry: this.fullDataParam.modal.dataModal.industry,
                        slug_name: this.fullDataParam.modal.dataModal.slug_name,
                        user: this.fullDataParam.modal.dataModal.user_id,
                    }
                    try {
                        const res = await axios.patch(`${this.fullDataParam.url}/${this.fullDataParam.modal.dataModal.id}`, fullData, {
                            method: 'PATCH',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + localStorage.getItem('token')
                            }
                        });
                        this.msg = "Cliente editado correctamente"
                        this.msgtoast = "completado"
                        setTimeout(() => {
                            this.msgtoast = res.data.message;
                            this.msgtoast = ''
                            this.cerrarModal()
                        }, 3000);

                    } catch (error) {
                        this.msg = "Error al crear el cliente"
                        this.msgtoast = "error"
                        setTimeout(() => {
                            this.msgtoast = error;
                            this.msgtoast = ''
                            this.cerrarModal()
                        }, 3000);
                    }
                    this.loading = false
                }else {
                    this.msg = "Todos los campos son obligatorios"
                    this.msgtoast = "error"
                    setTimeout(() => {
                        this.msgtoast = ''
                    }, 5000);
                }
                this.cerrarConfirmar ()
            },

            cerrarConfirmar () {
                this.btnconfirmar = '';
            },

            confirmarbtn(text) {
                this.btnconfirmar = text
            },
		},
        mounted() {
            this.userData = JSON.parse(localStorage.getItem("userData"));
            if (this.userData.user != 'staff') {
                if (this.userData.user != 'superuser') {
                    this.$router.push('/');
                }
            }
			this.conUsuarios()
        },
    }
</script>

<style scoped>
.btnconfirmar {
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        width: 100vw;
        height: 100vh;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1500;
    }
    .btnconfirmar div {
        background-color: white;
        border-radius: 10px;
        padding: 25px;
    }
</style>