import Vue from 'vue'
import VueRouter from 'vue-router'
import homeView from '../views/homeView.vue'
import loginView from '@/views/loginView.vue'
import productosView from '@/views/productosView.vue'
import invewntarioView from '@/views/inventarioView.vue'
import nuevaOrdenView from '@/views/nuevaOrdenView.vue'
import clientesView from '@/views/clientesView.vue'
import usuariosView from '@/views/usuariosView.vue'
import bodegasView from '@/views/bodegasView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: homeView
  },
  {
    path: '/login',
    name: 'Login',
    component: loginView,
    meta: { isPublic: true }
  },
  {
    path: '/productos',
    name: 'Productos',
    component: productosView
  },
  {
    path: '/inventario',
    name: 'Inventario',
    component: invewntarioView
  },
  {
    path: '/nuevaorden',
    name: 'Nuevaorden',
    component: nuevaOrdenView
  },
  {
    path: '/clientes',
    name: 'Clientes',
    component: clientesView
  },
  {
    path: '/usuarios',
    name: 'Usuarios',
    component: usuariosView
  },
  {
    path: '/bodegas',
    name: 'Bodegas',
    component: bodegasView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
