<template>
    <div class>
        <img src="/logo4.png" alt="Aliada"><br />
        <div class="gestion">
            <h1>Gestión de inventarios</h1>
        </div>
        <form-aliada />
    </div>
</template>

<script>
    import FormAliada from '@/components/FormAliada.vue';

    export default {
        name: 'LoginView',
        components: {
            FormAliada
        }
    }
</script>
<style scoped>
    h1 {
        color: #002344;
    }

    .gestion {
        margin-bottom: 2rem;
    }

    img {
        margin-top: 3rem;
        margin-bottom: 1rem;
        width: 250px;
    }
</style>
