<template>
    <div>
        <div class="container text-center mb-5 mt-5 py-5" id="conten">
            <div v-if="vistaDetalle == false">
                <h1 class="mb-3">Gestión de inventarios</h1>

                <!-- buscar -->
                <div class="d-flex justify-content-center align-items-center mt-4">
                    <div class="mr-2 font-weight-bold">Filtrar</div>
                    <!-- imput para buscar -->
                    <input class="form-control d-inline-block w-auto ml-2" v-model="valorFiltrado"
                        @keyup="buscarProve(valorFiltrado)">
                </div>

                <!-- Cargando... -->
                <div v-if="loading" class="mt-5">
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>

            <listadoOrdenes v-if="vistaDetalle" :detalleProveedor="detalleProveedor" @cerrar="cerrarDetalle"/>

            <!-- Listar los Proveedores -->
            <div v-if="proveedores[0] != undefined && vistaDetalle == false">
                <div class="container d-flex justify-content-center flex-wrap mt-5">
                    <div v-for="(proveedor, i) in proveedoresPaginados" :key="i" class="p-3">
                        <button class="btn btn-primary text-uppercase font-weight-bold btnprovee px-4 py-2" @click="verDetalle(proveedor)">{{ proveedor.name }}</button>
                    </div>
                </div>


                <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-center">
                        <li class="page-item" v-if="page > 0">
                            <a class="page-link pointer" aria-label="Previous" @click.prevent="paginacionatras()">
                                <span aria-hidden="true">&laquo;</span>
                                <span class="sr-only">Primera</span>
                            </a>
                        </li>
                        <li class="page-item">
                            <a class="page-link pointer">{{ (page + 1) }}</a>
                        </li>
                        <li class="page-item"
                            v-if="(page + 1) < Math.ceil(this.proveedoresFiltrados.length / this.itemLength)">
                            <a class="page-link pointer" aria-label="Next" @click.prevent="paginacionadelante()">
                                <span aria-hidden="true">&raquo;</span>
                                <span class="sr-only">Ultima</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>

        </div>
    </div>
</template>

<script>
    import listadoOrdenes from '@/components/inventarioComponente/listadoOrdenes.vue';
    import axios from "axios";
    const url = "http://apiinventory.eba-zdkhf2sk.us-west-2.elasticbeanstalk.com/v1";

    export default {
        name: 'inventarioView',
        components: {
            listadoOrdenes
        },
        data() {
            return {
                loading: false,
                selected: 0,
                proveedores: [],
                valorFiltrado: '',
                proveedoresFiltrados: [],
                proveedoresPaginados: [],
                page: 0,
                itemLength: 5,

                /* Detalle de proveedor */
                vistaDetalle: false,
                detalleProveedor: {},
            }
        },
        methods: {
            /* consultar lista de proveedores*/
            async conProvedores() {
                this.loading = true;
                try {
                    const res = await axios.get(url + `/customers`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                    });
                    this.proveedores = res.data.results;
                    this.proveedoresFiltrados = this.proveedores;
                    this.paginacion();
                } catch (error) {
                    console.log(error);
                }
                this.loading = false;
            },

            paginacion() {
                let i = 0;
                let a = this.page * this.itemLength;
                while (i < this.itemLength) {
                    for (let e = 0; e < this.itemLength; e++) {
                        if (this.proveedoresFiltrados[a] == undefined) {
                            a++;
                        } else {
                            this.proveedoresPaginados.push(this.proveedoresFiltrados[a]);
                            a++;
                        }
                    }
                    i = this.itemLength;
                }
                this.loading = false;
            },
            paginacionadelante() {
                this.loading = true;
                this.proveedoresPaginados = [];
                this.page = this.page + 1;
                this.paginacion();
            },
            paginacionatras() {
                this.loading = true;
                this.proveedoresPaginados = [];
                this.page = this.page - 1;
                this.paginacion();
            },

            buscarProve(a) {
                this.proveedoresPaginados = [];
                this.proveedoresFiltrados = [];
                this.page = 0;
                this.proveedoresFiltrados = this.proveedores.filter(proveedor => {
                    return proveedor.name.toLowerCase().includes(a.toLowerCase());
                });
                this.paginacion();
            },

            verDetalle(proveedor) {
                this.detalleProveedor = proveedor;
                this.vistaDetalle = true;
            },

            cerrarDetalle() {
                this.vistaDetalle = false;
                this.detalleProveedor = {};
            }
        },
        mounted() {
            this.conProvedores();
        }
    }
</script>

<style scoped>
    .btnprovee {
        border-radius: 25px;
        font-size: 1.2em;
        min-width: 150px;
        transition: transform 0.2s;
    }

    .btnprovee:hover {
        transform: scale(1.04);
        transition: transform 0.2s;
    }
</style>