<template>
    <div class="form-group">
        <div class="container">
            <label for="user">Usuario</label>
            <input type="email" v-model="email"
                class="form-control form-control-lg rounded-pill border border-primary text-center" name="user"
                id="user" aria-describedby="helpId" placeholder="Introduce tu correo" required>
            <small id="helpId" class="form-text text-muted"></small>
        </div>
        <div class="container" v-if="estado">
            <label for="pass">Contraseña</label>
            <input type="password" v-model="password"
                class="form-control form-control-lg rounded-pill border border-primary text-center" name="pass"
                id="pass" aria-describedby="helpId" placeholder="Introduce tu contraseña">
            <small id="helpId" class="form-text text-muted"></small>
        </div>
        <button v-if="!estado" type="button" id="btn1" class="rounded-pill btnLogin" @click="verCorreo">Verificar</button>
        <button v-if="estado" type="button" id="btn2" class="rounded-pill btnLogin" @click="LogIn">Log in</button>
    </div>
</template>

<script>
    const URL = "http://apiinventory.eba-zdkhf2sk.us-west-2.elasticbeanstalk.com"
    const URL_LOGIN = URL + "/auth/login"
    import axios from "axios";
    export default {
        name: "FormAliada",
        component: {},
        data() {
            return {
                estado: true,
                resError: false,
                email: "",
                password: "",
            }
        },
        methods: {
            respuestaForm: function (respuesta) {
                return JSON.stringify(respuesta, null, 2);
            },
            async verCorreo() {
                if (this.email == "") {
                    this.boxTwo = ''
                    this.$bvModal.msgBoxOk("Ingrese un correo", {
                            title: 'Error',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'danger',
                            headerClass: 'p-2 border-bottom-0',
                            footerClass: 'p-2 border-top-0',
                            centered: true
                        })
                        .then(value => {
                            this.boxTwo = value
                        })
                } else {
                    try {
                        let res = await axios.get(`${URL}/user_login_type?email=` + this.email);
                        if (res.data[0].user_login_type == 2) {
                            this.estado = false;
                            this.$router.push({
                                name: "homeView"
                            });
                        } else {
                            this.estado = true;
                        }
                    } catch (error) {
                        this.boxTwo = ''
                        this.$bvModal.msgBoxOk(error.message, {
                                title: 'Error',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'danger',
                                headerClass: 'p-2 border-bottom-0',
                                footerClass: 'p-2 border-top-0',
                                centered: true
                            })
                            .then(value => {
                                this.boxTwo = value
                            })
                    }
                }
            },

            async LogIn() {
                const userLog = {
                    username: this.email,
                    password: this.password
                }
                try {
                    let res = await axios.post(`${URL_LOGIN}`, userLog);
                    if (res.status != 200) {
                        throw ("Autenticacion incorrecta"); //este metodo es de prueba porque no tengo la info sobre lo que me puede responder la API
                    } else {
                        localStorage.setItem("token", res.data.token.access);
                        if (res.data.is_staff == true) {
                            if (res.data.is_superuser == true) {
                                localStorage.setItem("userData", JSON.stringify({
                                    customer: res.data.customer,
                                    user: "superuser",
                                    bodega: res.data.warehouse,
                                    id: res.data.user_id
                                }));
                            }else {
                                localStorage.setItem("userData", JSON.stringify({
                                    customer: res.data.customer,
                                    user: "staff",
                                    bodega: res.data.warehouse
                                }));
                            }
                        } else {
                            localStorage.setItem("userData", JSON.stringify({
                                customer: res.data.customer,
                                user: "standard",
                                bodega: res.data.warehouse,
                            }));
                        }
                        this.$router.push({name: "Home"})
                    }
                } catch (error) {
                    this.boxTwo = ''
                    this.$bvModal.msgBoxOk(error.message, {
                        title: 'Usuario o contraseña incorectos',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        headerClass: 'p-2 border-bottom-0',
                        footerClass: 'p-2 border-top-0',
                        centered: true
                    })
                }
            }
        }
    }
</script>

<style scoped>
    label {
        color: #002344;
        font-size: large;
        margin-top: 10px;
        font-weight: bold;
    }

    button {
        width: 10em;
        background-color: #3a18e5;
        border-color: #3a18e5;
        color: aliceblue;
        margin: 10px;
    }

    .form-group {
        position: auto;
        top: 10%;
        bottom: 50%;
    }

    input {
        padding: -2rem;
    }

    .btnLogin {
        padding: 5px;
        font-size: 20px;
        margin-top: 2rem;
        width: 90%;
        max-width: 660px;
    }

    @media screen and (min-width: 800px) {
        .container {
            padding-left: 15rem;
            padding-right: 15rem;
        }
    }
</style>